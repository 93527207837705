
<template>
  <div class="address-edit-box">
    <s-header :name="`${type == 'add' ? $t('t.addAddress') : $t('t.editAddress')}`"></s-header>

    <van-form @submit="onSave" class="marTop60">
      <van-field
        v-model="ruleForm.userName"
        name="userName"
        :label="$t('t.name')"
        :placeholder="$t('t.enteryourname')"
        :rules="[{ required: true, message: $t('t.enteryourname')}]"
      />
      <van-field
        v-model="ruleForm.userPhone"
        name="userPhone"
        :label="$t('t.telephone')"
        :placeholder="$t('t.enteryourtelephone')"
        :rules="[{ required: true, message: $t('t.enteryourtelephone')}]"
      />
      <!--<van-field-->
        <!--readonly-->
        <!--clickable-->
        <!--name="picker"-->
        <!--:value="addressName"-->
        <!--:label="$t('t.address')"-->
        <!--:placeholder="$t('t.selectaddress')"-->
        <!--:rules="[{ required: true, message: $t('t.selectaddress')}]"-->
        <!--@click="addressPicker = true"-->
      <!--/>-->
      <!--<van-popup v-model="addressPicker" position="bottom">-->
        <!--<van-row type="flex" align="middle" justify="space-between" class="padding15 size16 fontB borderButtE4">-->
          <!--<van-row>{{$t('t.selectaddress')}}</van-row>-->
          <!--<van-icon name="cross"  class="size18" @click="addressPicker = false" />-->
        <!--</van-row>-->
        <!--&lt;!&ndash;<van-field&ndash;&gt;-->
          <!--&lt;!&ndash;v-model="ruleForm.detailAddress"&ndash;&gt;-->
          <!--&lt;!&ndash;name="detailAddress"&ndash;&gt;-->
          <!--&lt;!&ndash;label="邮编搜索"&ndash;&gt;-->
          <!--&lt;!&ndash;placeholder="邮编搜索"&ndash;&gt;-->
        <!--&lt;!&ndash;/>&ndash;&gt;-->
        <!--<van-cascader-->
          <!--:show-header="false"-->
          <!--:field-names="fieldNames"-->
          <!--v-model="ruleForm.systemAddressId"-->
          <!--title=""-->
          <!--:options="options"-->
          <!--@change="onChange"-->
          <!--@finish="onFinish">-->
        <!--</van-cascader>-->
      <!--</van-popup>-->
      <van-field
        v-model="ruleForm.detailAddress"
        name="detailAddress"
        :label="$t('t.detailedaddress')"
        :placeholder="$t('t.enterfulladdress')"
        :rules="[{ required: true, message: $t('t.enterfulladdress')}]"
      />
      <van-field name="switch" :label="$t('t.setdefaultaddress')">
        <template #input>
          <van-switch v-model="ruleForm.isDefault" size="20" />
        </template>
      </van-field>
      <van-row class="padding15">
        <van-row v-if="ruleForm.systemAddressId" class="marBott20">
          {{$t('t.noteaddress')}}<span class="fontB colorTheme paddLR5 size14">【{{codCover?$t('t.support'):$t('t.nonsupport')}}】</span>{{$t('t.cashondelivery')}}
        </van-row>
        <van-button round block type="info" color="#e92d74" native-type="submit">{{$t('t.save')}}</van-button>
      </van-row>
    </van-form>
    <van-row class="paddLR15">
      <van-button v-if="type == 'edit'" @click="onDelete" round plain block type="info" color="#e92d74" class="marTop15">{{$t('t.delete')}}</van-button>
    </van-row>
  </div>
</template>

<script>

import sHeader from '@/components/SimpleHeader'
import { addAddress, EditAddress, DeleteAddress, getAddressDetail,getSelectAddress } from '../service/address'
export default {
  components: {
    sHeader
  },
  data() {
    return {
      type: 'add',
      addressId: '',
      from: '',

      ruleForm:{
        systemAddressId: '',
        userName:'',
        userPhone:'',
        detailAddress:'',
        isDefault:true
      },
      addressName:'',
      options: [],
      addressPicker:false,
      fieldNames: {
        text: 'name',
        value: 'id',
        children: 'children',
      },
      requestForm:{
        pulauIsland:null,
        provinsiProvince:null,
        kotaCityName:null,
        districtKecamatan:null,
      },
      codCover:false,
    }
  },
  async mounted() {
    this.getAreaList()
    const { addressId, type, from } = this.$route.query
    this.addressId = addressId
    this.type = type
    this.from = from || ''
    if (type == 'edit') {
      const { data: addressData } = await getAddressDetail(addressId)
      this.addressName =
        addressData.newBeeMallAddress.pulauIsland+'/'+
        addressData.newBeeMallAddress.provinsiProvince+'/'+
        addressData.newBeeMallAddress.kotaCityName+'/'+
        addressData.newBeeMallAddress.districtKecamatan+'/'+
        addressData.newBeeMallAddress.subdistrictKelurahan

      this.requestForm.pulauIsland = addressData.newBeeMallAddress.pulauIsland
      this.requestForm.provinsiProvince = addressData.newBeeMallAddress.provinsiProvince
      this.requestForm.kotaCityName = addressData.newBeeMallAddress.kotaCityName
      this.requestForm.districtKecamatan = addressData.newBeeMallAddress.districtKecamatan

      this.ruleForm.systemAddressId = addressData.systemAddressId
      this.ruleForm.userName = addressData.userName
      this.ruleForm.userPhone = addressData.userPhone
      this.ruleForm.detailAddress = addressData.detailAddress
      this.ruleForm.isDefault = !!addressData.defaultFlag


    }
  },
  methods: {
    onFinish({ selectedOptions }) {
      this.codCover = selectedOptions[selectedOptions.length-1].codCover == 'Y'?true:false
      this.addressPicker = false;
      this.addressName = selectedOptions.map((option) => option.name).join('/');
    },
    // 从接口请求获取第一层的数据，---比如北京
    async getAreaList() {
      const res = await getSelectAddress()
      res.data.forEach(item => {
        this.options.push({
          name: item.name,
          id: item.id,
          children: item.children || []// 这个很关键
        })
      })
    },
    async onChange({ value, selectedOptions, tabIndex }) {
      if(tabIndex ===0) {
        this.requestForm.pulauIsland = value
        this.requestForm.provinsiProvince = null
        this.requestForm.kotaCityName = null
        this.requestForm.districtKecamatan = null
      }
      if(tabIndex ===1) {
        this.requestForm.provinsiProvince = value
        this.requestForm.kotaCityName = null
        this.requestForm.districtKecamatan = null
      }
      if(tabIndex ===2) {
        this.requestForm.kotaCityName = value
        this.requestForm.districtKecamatan = null
      }
      if(tabIndex ===3) {
        this.requestForm.districtKecamatan = value
      }
      await getSelectAddress(this.requestForm).then(res => {
        if(tabIndex>=3){
          res.data.map(item=>{
            if(item.children.length==0){ // 这个是和后端约定了如果没有子级，返回一个空数组，可以自己和后端约定一个可判断的值
              delete item.children
            }
          })
        }
        this.addTree(selectedOptions, res.data, value)
      })
    },
    addTree(selectedOptions, children, id) {
      selectedOptions.forEach(item => {
        if (item.id === id) {
          item['children'] = children
        }
      })
    },





    async onSave(content) {
      let params = this.ruleForm
      params.defaultFlag = this.ruleForm.isDefault ? 1 : 0
      if (this.type == 'edit') {
        params['addressId'] = this.addressId
      }
      const { message } = await this.type == 'add' ? addAddress(params) : EditAddress(params)
      this.$toast(this.$t('t.saveSuccess'))
      setTimeout(() => {
        this.$router.push({ path: `address?from=${this.from}` })
      }, 1000)
    },
    async onDelete() {
      const { data } = await DeleteAddress(this.addressId)
      this.$toast(this.$t('t.deletSuccess'))
      setTimeout(() => {
        this.$router.push({ path: `address?from=${this.from}` })
      }, 1000)
    }
  }
}
</script>

<style lang="less">
  @import '../common/style/mixin';
  .address-edit-box {
    margin-top: 44px;
    .van-address-edit {
      .van-button--danger {
        background: @primary;
        border-color: @primary;
      }
      .van-switch--on {
        background: @primary;
      }
    }
  }
  .marTop60{margin-top: 60px}
</style>
