/*cn.js*/

const cn = {
  t:{
    //TODO Address
    addressManagement:'地址管理', //
    acquiesce:'默认', //
    //TODO AddressEdit
    addAddress:'新增地址', //
    editAddress:'编辑地址', //
    //TODO Cart
    shoppingCart:'购物车', //
    settleAccount:'结算', //
    selectAll:'全选', //
    cartEmpty:'购物车空空空如也', //
    goHome:'前往首页', //
    editing:'修改中...', //
    placeholder1:'请选择商品进行结算', //
    //TODO CreateOrder
    generateOrder:'生成订单', //
    commodityAmount:'商品金额', //
    //TODO Home
    newProduct:'新品上线', //
    hotCommodity:'热门商品', //
    latestRecommendation:'最新推荐', //
    //TODO Login
    username:'用户名', //
    placeholder4:'请填写用户名', //
    password:'密码', //
    placeholder5:'请填写密码', //
    registerNow:'立即注册', //
    alreadyLoggedAccount:'已有登录账号', //
    placeholder6:'验证码未填或填写错误', //
    registeredSuccessfully:'注册成功', //
    //TODO Order
    myOrder:'我的订单', //
    all:'全部', //
    due:'待付款', //
    confirmed:'待确认', //
    waitingSent:'待发货', //
    shipped:'已发货', //
    transactionCompleted:'交易完成', //
    orderTime:'订单时间', //
    //TODO OrderDetail
    orderDetails:'订单详情', //
    orderStatus:'订单状态', //
    orderNumber:'订单编号', //
    confirmReceipt:'确认收货', //
    goPay:'去支付', //
    cancelOrder:'取消订单', //
    distributionMode:'配送方式', //
    ordinaryExpress:'普通快递', //
    goodsDesc:'全场包邮', //
    confirmCancellation:'确认取消订单?', //
    cancelSuccess:'取消成功', //
    isConfirmReceipt:'是否确认收货?', //
    confirmSuccess:'确认成功', //
    immediateEvaluation:'立即评价',
    comprehensiveEvaluation:'综合评价',
    evaluationContent:'评价内容',
    evaluationContentPlaceholder:'展开说说对商品的想法吧...',
    photoVideo:'图片/视频',
    logisticsInfo:'物流单号',//'Tracking Number',
    copy:'复制',//copy''
    //TODO ProductDetail
    productDetails:'商品详情', //
    exemptFromPostage:'免邮费 顺丰快递', //
    salesVolume:' 销量', //
    evaluate:'评价', //
    purchased:'已购', //
    negativeComment:'差评', //
    mediumReview:'中评', //
    goodReputation:'好评', //
    overviewParameter:'概述参数', //
    customerService:'客服', //
    addCart:'加入购物车', //
    buyNow:'立即购买', //
    addSuccessfully:'添加成功', //
    //TODO ProductList
    search:'搜索', //
    recommended:'推荐', //
    new:'新品', //
    price:'价格', //
    //TODO Setting
    accountManagement:'账号管理', //
    nickname:'昵称', //
    personalSignature:'个性签名', //
    changePassword:'修改密码', //
    avatar:'头像', //
    newPasswordPlaceholder:'请输入新密码', //
    save:'保存', //
    logOut:'退出登录', //
    //TODO User
    mine:'我的', //
    loginName:'登录名', //
    aboutUs:'关于我们', //
    //TODO NavBar
    home:'首页',//
    sort:'分类', //
    //TODO Common
    loading:'加载中...', //
    logIn:'登录', //
    signIn:'注册', //
    noMore:'没有更多了', //
    saveSuccess:'保存成功', //
    deletSuccess:'删除成功', //
    placeholder:'搜索', //
    changeLang:'切换语言（中文）', //
    //TODO Pay
    payName:'支付姓名', //'支付姓名',
    wallet:'钱包', //'钱包',
    internetBank:'网银', //'网银',
    cod:'货到付款',// 货到付款
    placeholder7:'请填写支付姓名', //'请填写支付姓名',
    pay:'支付',//
    scanQr:'扫码',//
    qris:'Qris 支付',//Qris支付
    bankcardCode:'银行卡编码',//
    confirmPayment:'确认支付',//
    pleaseSelectCardCode:'请选择银行卡编码',//
    confirmCancelPayment:'确认取消支付吗？',//
    //TODO Share
    shareProfits:'分享赚金',//
    invitationLink:'我的邀请链接',//
    invitationStep:'邀请步骤',//
    chooseOne:'以下任选其一',//
    tips1:'1.复制上面的邀请链接，发送给您的好友',//
    tips2:'2.选择一款下面你喜欢的海报下载到手机，可以发送给您的好友',//
    tips:'Tips：好友通过您的邀请链接或者扫描您的海报二维码成功注册后，Ta将永久成为您的粉丝，未来Ta产生的订单金额，您都有奖励，会自动计入您的账户中',//
    posters:'一键生成专属海报',//
    saveScreenshot:'长按/截屏保存',//
    cancel:'关闭',//
    youHaveGot:'您已赚取',//
    bonus:'奖金',//


    bonusDetail:'奖金明细',// 奖金明细
    withdrawalApplication:'提现申请',// 提现申请
    totalContribution:'贡献总金额',// 贡献总金额
    amountWithdrawalNotbeLess1:'提现申请金额不能小于1',// 提现申请金额不能小于1
    withdrawalApplicationReview:'提现申请已发起成功，请等待后台审核',// 提现申请已发起成功，请等待后台审核
    //TODO GoogleRegister
    bindAccount:'绑定账号',// 绑定账号
    bindingSuccessful:'绑定成功',// 绑定成功
    //TODO DoTask
    moneyDoingMissions:'做任务赚金',// 做任务赚金
    doTaskCollectGift:'做任务，薅礼金',// 做任务，薅礼金
    taskStep:'任务步骤',// 任务步骤
    icon:'图标',// 图标
    goToProductDetailsAny:'前往任何商品的详情页',// 前往任何商品的详情页
    clickTopRight:'点击右上角',// 点击右上角
    copyProductDetailsLink:'复制商品详情链接',// 复制分享复制商品详情链接
    sendYourFriends:'发送给您的朋友点击',// 发送给您的朋友点击
    view:'查看',// 查看
    canObtainCorresponding:'即可获得相应',// 即可获得相应
    rewards:'奖励',// 奖励
    taskReward:'任务奖励',// 任务奖励
    be:'被',// 被
    personView:'人查看',// 人查看
    gain:'得到',// 得到
    note:'注：商品详情链接被查看的人数越多',// 注：商品详情链接被查看的人数越多
    moreRewards:'获得奖励就会越多',// 获得奖励就会越多
    countdownMission:'您的任务倒计时',// 您的任务倒计时
    day:'天',// 天
    when:'时',// 时
    points:'分',// 分
    seconds:'秒',// 秒
    taskProgress:'您的任务进度',// 您的任务进度
    currentlyCompletedNumber:'当前任务完成数量',// 当前任务完成数量
    notReapply:'请勿重新申请',// 请勿重新申请
    applicationSuccessful:'申请成功',// 申请成功
    loginSuccessful:'登录成功',// 登录成功
    googleAuthorizationFailed:'谷歌授权失败，请刷新重试',// 谷歌授权失败，请刷新重试
    accumulateRewards:'累计得到奖励',// 累计得到奖励
    bonusBalance:'奖金余额',// 奖金余额
    accumulatedBonus:'累计奖金',// 累计奖金
    withdrawing:'提现中',// 提现中
    sureWithdrawal:'确定将全部奖金余额发起提现申请？',// 确定将全部奖金余额发起提现申请？


    name:'姓名',//姓名
    enteryourname:'请输入姓名',//请输入姓名
    telephone:'电话',//电话
    enteryourtelephone:'请输入电话',//请输入姓名
    address:'地址',//地址
    selectaddress:'请选择地址',//请选择地址
    detailedaddress:'详细地址',//详细地址
    enterfulladdress:'请输入详细地址',//请输入详细地址
    setdefaultaddress:'设为默认地址',//设为默认地址
    noteaddress:'注：当前地址',//注：当前地址
    support:'支持',//支持
    nonsupport:'不支持',//不支持
    cashondelivery:'货到付款（COD）',//货到付款（COD）
    total:'合计',//合计
    freight:'运费',//运费
    currentshippingcosts:'当前运费为：',//当前运费为：
    rememberCourier:'请记得连同货款交付给快递员哦~',//请记得连同货款交付给快递员哦~
    delete:'删除',//删除

    tip:'提示',//Tips
    payCloseTip:'系统检测到您当前还未支付成功，未截图且关闭此窗口可能导致支付失败，是否关闭?',//The system detects that you have not successfully paid yet, and closing this window without taking a screenshot may cause payment failure. Do you want to close it?
    sure:'确定',//Sure
    paymentSuccess:'支付成功',//paymentSuccess

    firstSingleMinus1:'首单立减 ', //首单立减2000 ！！！
    firstSingleMinus2:' ！！！', //首单立减2000 ！！！
    theFirstOrder1:'首次下单成功，即可获得',//首次下单成功，即可获得2000
    theFirstOrder2:'奖励，下单成功后，可在【我的】领取，赶紧下单吧~', //奖励，下单成功后，可在个人中心领取，赶紧下单吧~
    congratulationOrder:'恭喜您，下单成功！！！', //恭喜您，下单成功！！！
    youWillReceive:'您将获得以下权益', //您将获得以下权益
    aSuccessful1:'成功获得', //成功
    aSuccessful2:'奖励，稍后可在【我的】查看', //获得2000奖励，稍后可在个人中心查看
    youCanShare1:'可分享此链接给您朋友下单，他将获得', //可分享此链接给您朋友下单，他将获得
    youCanShare2:'奖励，以及您也会获得相应佣金，详见【我的】【分享赚金】', //2000奖励，以及您也会获得相应佣金，详见个人中心【分享赚金】
    youCanShareView:'可分享此链接给您朋友查看，查看得人越多，你也会获得相应的查看奖励佣金，详见【我的】【做任务赚金】', //可分享此链接给您朋友查看，查看得人越多，你也会获得相应的查看奖励佣金，详见个人中心【做任务赚金】
    goToPersonal:'前往【我的】', //前往个人中心
    copyLink:'复制链接', //复制链接
    systemAssigns:'系统生成账号密码', //系统分配账号密码

    theServer:'服务端异常，请联系管理员！',// 服务端异常，请联系管理员！
    automaticLogin:'自动登录',// 自动登录
    theSystem:'系统检测您当前未登录，已自动为您分配账号，或者已有账号自行登录/注册。',// 系统检测您当前未登录，已自动为您分配账号，或者已有账号自行登录/注册。
    noteAutonomous:'注：分配账号以及密码可在个人中心修改',// 注：分配账号以及密码可在个人中心修改
    autonomousLogin:'自主登录/注册',// 自主登录/注册
    assignedAccount :'分配账号登录',// 分配账号登录
    noteRemember:'注：记得复制后自行保存哦，后续可在个人中心修改账号和密码以及领取奖励！',// 注：记得复制后自行保存哦，后续可在个人中心修改账号和密码以及领取奖励！
    successfulReplication:'复制成功',// 复制成功

  },
}

export default cn;
