
import axios from '../utils/axios'

export function createOrder(params) {
  return axios.post('/saveOrder', params);
}

export function createOrderCalc(params) {
  return axios.post('/calculate/order', params);
}

export function getOrderList(params) {
  return axios.get('/order', { params });
}

export function getOrderDetail(id) {
  return axios.get(`/order/${id}`);
}

export function cancelOrder(id) {
  return axios.put(`/order/${id}/cancel`);
}

export function confirmOrder(id) {
  return axios.put(`/order/${id}/finish`)
}

// export function payOrder(params) {
//   return axios.get('/paySuccess', { params })
// }
export function queryPay(params) {
  return axios.get('/queryPay', { params })
}
export function saveEvaluate(params) {
  return axios.post('/saveOrUpdateEvaluate', params)
}
// export function sendCode(params) {
//   return axios.get('/send/pay/message', { params })
// }
// export function sendPay(params) {
//   return axios.get('/send/pay', { params })
// }
// export function payFn(params) {
//   return axios.get('/pay/verify', { params })
// }
export function payAfter(params) {
  return axios.get('/arrival/pay', { params })
}



