/* en.js */

const en = {
  t:{
    // TODO Address
    addressManagement:'My Addresses', //'地址管理',
    acquiesce:'Default', //'默认',
  // TODO AddressEdit
    addAddress:'Add New Address', //'新增地址',
    editAddress:'Edit', //'编辑地址',
  // TODO Cart
    shoppingCart:'Shopping Cart', //'购物车',
    settleAccount:'Check Out', //'结算',
    selectAll:'All', //'全选',
    cartEmpty:'Oops, Your Shopping Cart is Empty', //'购物车空空空如也',
    goHome:'Go Shopping Now', //'前往首页',
    editing:'Modifying', //'修改中...',
    placeholder1:'You have not selected any items for checkout', //'请选择商品进行结算',
  // TODO CreateOrder
    generateOrder:'Generate Order', //'生成订单',
    commodityAmount:'Total', //'商品金额',
  // TODO Home
    newProduct:'Latest', //'新品上线',
    hotCommodity:'Top Sales', //'热门商品',
    latestRecommendation:'Relevance', //'最新推荐',
  // TODO Login
    username:'Name', //'用户名',
    placeholder4:'Enter user name', //'请填写用户名',
    password:'Password', //'密码',
    placeholder5:'Enter password', //'请填写密码',
    registerNow:'Register Now', //'立即注册',
    alreadyLoggedAccount:'Login with Password', //'已有登录账号',
    placeholder6:'Verification code not filled or filled incorrectly', //'验证码未填或填写错误',
    registeredSuccessfully:'Login was successful', //'注册成功',
  // TODO Order
    myOrder:'My Purchases', //'我的订单',
    all:'All', //'全部',
    due:'To Pay', //'待付款',
    confirmed:'Confirming', //'待确认',
    waitingSent:'To Ship', //'待发货',
    shipped:'To Receive', //'已发货',
    transactionCompleted:'Comoleted', //'交易完成',
    orderTime:'Order time', //'订单时间',
  // TODO OrderDetail
    orderDetails:'Order Details', //'订单详情',
    orderStatus:'Order Status', //'订单状态',
    orderNumber:'Order ID', //'订单编号',
    confirmReceipt:'Confirm receipt', //'确认收货',
    goPay:'To pay', //'去支付',
    cancelOrder:'Cancel Order', //'取消订单',
    distributionMode:'Shipping Information', //'配送方式',
    ordinaryExpress:'Regular express delivery', //'普通快递',
    goodsDesc:'Free shipping', //'全场包邮',
    confirmCancellation:'Confirm Cancel Order', //'确认取消订单?',
    cancelSuccess:'Successfully Canceled', //'取消成功',
    isConfirmReceipt:'Confirm receipt?', //'是否确认收货?',
    confirmSuccess:'Confirmed successfully', //'确认成功',
    immediateEvaluation:'Evaluation Now', //立即评价,
    comprehensiveEvaluation:'Rating', //综合评价,
    evaluationContent:'Content', //评价内容,
    evaluationContentPlaceholder:"Let's expand on the idea of the product...", //展开说说对商品的想法吧,
    photoVideo:'Photo/Video', //图片/视频,
    logisticsInfo:'Tracking Number',//'物流信息',
    copy:'Copy',//'复制'
  // TODO ProductDetail
    productDetails:'Product details', //'商品详情',
    exemptFromPostage:'Free shipping', //'免邮费 顺丰快递',
    salesVolume:' sold', //'销量',
    evaluate:'Review', //'评价',
    purchased:'Purchased', //'已购',
    negativeComment:'Negative Comment', //'差评',
    mediumReview:'Medium Review', //'中评',
    goodReputation:'Good Reputation', //'好评',
    overviewParameter:'Overview Parameter', //'概述参数',
    customerService:'Chat', //'客服',
    addCart:'Add to Cart', //'加入购物车',
    buyNow:'Buy Now', //'立即购买',
    addSuccessfully:'Added to cart', //'添加成功',
  // TODO ProductList
    search:'Search', //'搜索',
    recommended:'Recommend', //'推荐',
    new:'Latest', //'新品',
    price:'Price', //'价格',
  // TODO Setting
    accountManagement:'Account Settings', //'账号管理',
    nickname:'Name', //'昵称',
    personalSignature:'Bio', //'个性签名',
    changePassword:'Change password', //'修改密码',
    avatar:'avatar', //'头像',
    newPasswordPlaceholder:'Please enter your new password', //'请输入新密码',
    save:'Submit', //'保存',
    logOut:'Logout', //'退出登录',
  // TODO User
    mine:'Me', //'我的',
    loginName:'Username', //'登录名',
    aboutUs:'About', //'关于我们',
  // TODO NavBar
    home:'Home', //'首页',
    sort:'Categories', //'分类',
  // TODO Common
    loading:'Loading', //'加载中...',
    logIn:'LogIn', //'登录',
    signIn:'SignUp', //'注册',
    noMore:'No More', //'没有更多了',
    saveSuccess:'Success', //'保存成功',
    deletSuccess:'Successfully Deleted', //'删除成功',
    placeholder:'Search', //'请搜索您喜欢的商品',
    changeLang:'Switch Language（English）', //
  // TODO Pay
    payName:'Payment Name', //'支付姓名',
    wallet:'Wallet', //'钱包',
    internetBank:'e-bank', //'网银',
    cod:'Pay On Delivery',// 货到付款
    placeholder7:'Enter the payment name', //'请填写支付姓名',
    pay:'pay',//支付
    scanQr:'Scan Qr',//扫码
    qris:'Qris Pay',//Qris支付
    bankcardCode:'Bank Card Code',//银行卡编码
    confirmPayment:'Confirm payment',//确认支付
    pleaseSelectCardCode:'select the card code',//请选择银行卡编码
    confirmCancelPayment:'Do you confirm the cancellation of payment?',//确认取消支付吗？
  // TODO Share
    shareProfits:'Share profits',//分享赚金
    invitationLink:'Invitation Link',//我的邀请链接
    invitationStep:'Invitation Step',//邀请步骤
    chooseOne:'Just choose one',//以下任选其一
    tips1:'1. Copy the invitation link above and send it to your friends',//1.复制上面的邀请链接，发送给您的好友
    tips2:'2. Select one of the posters below that you like to download to your phone and send to your friends',//2.选择一款下面你喜欢的海报下载到手机，可以发送给您的好友
    tips:'Tips: After your friends successfully register through your invitation link or scan your poster QR code, they will permanently become your fans, and the amount of orders generated by them in the future will be rewarded and automatically credited to your account',//Tips：好友通过您的邀请链接或者扫描您的海报二维码成功注册后，Ta将永久成为您的粉丝，未来Ta产生的订单金额，您都有奖励，会自动计入您的账户中
    posters:'Generate Posters',//一键生成专属海报
    saveScreenshot:'Hold down/Screen capture to save',//长按保存截屏
    cancel:'Cancel',//关闭
    youHaveGot:'you Have Got',//您已赚取
    bonus:'Bonus',//佣金


    bonusDetail:'Bonus detail',// 奖金明细
    withdrawalApplication:'Withdrawal application',// 提现申请
    totalContribution:'Total contribution',// 贡献总金额
    amountWithdrawalNotbeLess1:'The amount applied for withdrawal cannot be less than 1',// 提现申请金额不能小于1
    withdrawalApplicationReview:'The withdrawal application has been successfully initiated, please wait for the background review',// 提现申请已发起成功，请等待后台审核
    //TODO GoogleRegister
    bindAccount:'Bind account',// 绑定账号
    bindingSuccessful:'Binding successful',// 绑定成功
    //TODO DoTask
    moneyDoingMissions:'Make money doing missions',// 做任务赚金
    doTaskCollectGift:'Do the task, get the gift',// 做任务，薅礼金
    taskStep:'Task step',// 任务步骤
    icon:'Icon',// 图标
    goToProductDetailsAny:'Go to the product details page of any item',// 前往任何商品的详情页
    clickTopRight:'Click on top right corner',// 点击右上角
    copyProductDetailsLink:'Copy product details link',// 复制商品详情链接
    sendYourFriends:'Send to your friends with a click',// 发送给您的朋友点击
    view:'View',// 查看
    canObtainCorresponding:'Can obtain the corresponding',// 即可获得相应
    rewards:'Rewards',// 奖励
    taskReward:'Task reward',// 任务奖励
    be:'Be',// 被
    personView:'Person view',// 人查看
    gain:'Gain',// 得到
    note:'Note: The more people view the product details link',// 注：商品详情链接被查看的人数越多
    moreRewards:'The more rewards you get',// 获得奖励就会越多
    countdownMission:'Countdown to your mission',// 您的任务倒计时
    day:'Day',// 天
    when:'When',// 时
    points:'Points',// 分
    seconds:'Seconds',// 秒
    taskProgress:'Your task progress',// 您的任务进度
    currentlyCompletedNumber:'The number of tasks currently completed',// 当前任务完成数量
    notReapply:'Do not reapply',// 请勿重新申请
    applicationSuccessful:'Application successful',// 申请成功
    loginSuccessful:'Login successful',// 登录成功
    googleAuthorizationFailed:'Google authorization failed, please refresh and try again',// 谷歌授权失败，请刷新重试
    accumulateRewards:'Accumulate rewards',// 累计得到奖励
    bonusBalance:'Balance',// 奖金余额
    accumulatedBonus:'Accumulated',// 累计奖金
    withdrawing:'Withdrawing...',// 提现中
    sureWithdrawal:'Are you sure to apply for withdrawal of the entire bonus balance?',// 确定将全部奖金余额发起提现申请？

    name:'Name',//姓名
    enteryourname:'Enter your name',//请输入姓名
    telephone:'Telephone',//电话
    enteryourtelephone:'Enter phone number',//请输入姓名
    address:'Address',//地址
    selectaddress:'Select address',//请选择地址
    detailedaddress:'Detailed address',//详细地址
    enterfulladdress:'Enter the full address',//请输入详细地址
    setdefaultaddress:'Set default address',//设为默认地址
    noteaddress:'Note: Current address',//注：当前地址
    support:'Support',//支持
    nonsupport:'Nonsupport',//不支持
    cashondelivery:'Cash on delivery (COD)',//货到付款（COD）
    total:'Total',//合计
    freight:'Freight',//运费
    currentshippingcosts:'Current shipping costs are:：',//当前运费为：
    rememberCourier:'Please remember to deliver the payment to the Courier~',//请记得连同货款交付给快递员哦~
    delete:'Delete',//删除

    tip:'Tips',//提示
    payCloseTip:'The system detects that you have not successfully paid yet, and closing this window without taking a screenshot may cause payment failure. Do you want to close it?',//系统检测到您当前还未支付成功，未截图且关闭此窗口可能导致支付失败，是否关闭?
    sure:'Sure',//确定
    paymentSuccess:'Payment Success',//支付成功

    firstSingleMinus1:'First single minus ', //首单立减2000 ！！！
    firstSingleMinus2:'!!!', //首单立减2000 ！！！
    theFirstOrder1:'The first order is successful, you can get ',//首次下单成功，即可获得2000
    theFirstOrder2:' reward, after the order is successful, you can get it in the 【Me】, hurry to order ~', //奖励，下单成功后，可在个人中心领取，赶紧下单吧~
    congratulationOrder:'Congratulations, order success!!!', //恭喜您，下单成功！！！
    youWillReceive:'You will receive the following benefits', //您将获得以下权益
    aSuccessful1:'A successful ', //成功
    aSuccessful2:' award can be viewed later in the 【Me】', //获得2000奖励，稍后可在个人中心查看
    youCanShare1:'You can share this link to place an order with your friend, he will get ', //可分享此链接给您朋友下单，他将获得
    youCanShare2:' reward, and you will also get the corresponding commission, see 【Me】 【Share profits】', //2000奖励，以及您也会获得相应佣金，详见个人中心【分享赚金】
    youCanShareView:'You can share this link to your friends to view, view more people, you will also get the corresponding viewing reward commission, see  【Me】 【Make money doing missions】', //可分享此链接给您朋友查看，查看得人越多，你也会获得相应的查看奖励佣金，详见个人中心【做任务赚金】
    goToPersonal:'Go to 【Me】', //前往个人中心
    copyLink:'Copy link', //复制链接
    systemAssigns:'System generated account', //系统分配账号密码

    theServer:'The server is abnormal. Please contact the administrator.',// 服务端异常，请联系管理员！
    automaticLogin:'Automatic login',// 自动登录
    theSystem:'The system detects that you are not currently logged in, and has automatically assigned an account to you, or you have an account to log in/register by yourself.',// 系统检测您当前未登录，已自动为您分配账号，或者已有账号自行登录/注册。
    noteAutonomous:'Note: Assigned account number and password can be changed in the personal center',// 注：分配账号以及密码可在个人中心修改
    autonomousLogin:'Autonomous login/register',// 自主登录/注册
    assignedAccount :'Assigned account login',// 分配账号登录
    noteRemember:'Note: Remember to save after copying oh, you can modify the account and password in the personal center and receive rewards!',// 注：记得复制后自行保存哦，后续可在个人中心修改账号和密码以及领取奖励！
    successfulReplication:'Successful replication',// 复制成功
  },
}

export default en;





