<template>
  <div class="about">
    <s-header :name="'关于我们'"></s-header>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
export default {
  components: {
    sHeader
  },
}
</script>

<style lang="less" scoped>
</style>
