
import axios from '../utils/axios'

export function getDetail(id,params) {
  return axios.get(`/goods/detail/${id}`, { params });
}

export function getCategory(params) {
  return axios.get('/categories', { params });
}

export function search(params) {
  return axios.get('/search', { params });
}

export function getEvaluateList(params) {
  return axios.get('/evaluates', { params });
}
