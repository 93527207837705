
<template>
  <div class="order-box">
    <s-header :name="$t('t.myOrder')" :back="'/user'"></s-header>
    <van-tabs @change="onChangeTab" :color="'#e92d74'" :title-active-color="'#e92d74'" class="order-tab" v-model="status">
      <van-tab :title="$t('t.all')" name=''></van-tab>
      <van-tab :title="$t('t.due')" name="0"></van-tab>
      <van-tab :title="$t('t.confirmed')" name="1"></van-tab>
      <van-tab :title="$t('t.waitingSent')" name="2"></van-tab>
      <van-tab :title="$t('t.shipped')" name="3"></van-tab>
      <van-tab :title="$t('t.transactionCompleted')" name="4"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="order-list-refresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('t.noMore')"
        @load="onLoad"
        @offset="300"
      >
        <div v-for="(item, index) in list" :key="index" class="order-item-box" @click="goTo(item.orderNo)">
          <div class="order-item-header">
            <span>{{$t('t.orderTime')}}：{{ item.createTime }}</span>
            <span>{{ item.orderStatusString }}</span>
          </div>
          <van-card
            v-for="one in item.newBeeMallOrderItemVOS"
            :key="one.orderId"
            :num="one.goodsCount"
            :price="one.sellingPrice"
            desc=""
            :title="one.goodsName"
            :thumb="JSON.parse(one.goodsCoverImg)[0]">
            <template #price>
              {{one.sellingPrice | wq_mFormat}}
            </template>
          </van-card>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { getOrderList } from '../service/order'
import { prefix } from '@/common/js/utils'

export default {
  data() {
    return {
      status: '',
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      page: 1
    }
  },
  components: {
    sHeader
  },
  async mounted() {
    // this.loadData()
  },
  created(){
  },
  methods:{
    async loadData() {
      const { data, data: { list } } = await getOrderList({ pageNumber: this.page, status: this.status })
      this.list = this.list.concat(list)
      this.totalPage = data.totalPage
      this.loading = false;
      if (this.page >= data.totalPage) this.finished = true
    },
    onChangeTab(name, title) {
      this.status = name
      this.onRefresh()
    },
    goTo(id) {
      this.$router.push({ path: `order-detail?id=${id}` })
    },
    goBack() {
      this.$router.go(-1)
    },
    onLoad() {
      if (!this.refreshing && this.page < this.totalPage) {
        this.page = this.page + 1
      }
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      this.loadData()
    },
    onRefresh() {
      this.refreshing = true
      this.finished = false
      this.loading = true
      this.page = 1
      this.onLoad()
    },
  }
}
</script>

<style lang="less" scoped>
  @import '../common/style/mixin';
  .order-box {
    .order-tab {
      margin-top: 44px;
      position: fixed;
      left: 0;
      z-index: 10;
      width: 100%;
    }
    .order-list-refresh {
      margin-top: 68px;
      .van-card__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .van-pull-refresh__head {
        background: #f9f9f9;
      }
      .van-list {
        min-height: calc(100vh - 88px);
        background: #f9f9f9;
        margin-top: 20px;
      }
      .order-item-box {
        margin: 20px 10px;
        background-color: #fff;
        .order-item-header {
          padding: 10px 20px 0 20px;
          display: flex;
          justify-content: space-between;
        }
        .van-card {
          background-color: #fff;
          margin-top: 0;
        }
      }
    }
  }
</style>
