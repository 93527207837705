/* en.js */

const id = {
  t:{
    // TODO Address
    addressManagement:'Alamatku.', //'地址管理',
    acquiesce:'standar', //'默认',
  // TODO AddressEdit
    addAddress:'Tambah alamat baru', //'新增地址',
    editAddress:'sunting', //'编辑地址',
  // TODO Cart
    shoppingCart:'Kereta belanja', //'购物车',
    settleAccount:'Check Out.', //'结算',
    selectAll:'semua', //'全选',
    cartEmpty:'Ups, kereta belanja anda kosong', //'购物车空空空如也',
    goHome:'Pergi berbelanja sekarang', //'前往首页',
    editing:'memodifikasi', //'修改中...',
    placeholder1:'Anda belum memilih barang apa pun untuk diperiksa', //'请选择商品进行结算',
  // TODO CreateOrder
    generateOrder:'Membuat Pesanan', //'生成订单',
    commodityAmount:'jumlah barang', //'商品金额',
  // TODO Home
    newProduct:'Produk Baru', //'新品上线',
    hotCommodity:'Produk terlaris', //'热门商品',
    latestRecommendation:'Rekomendasi Terbaru', //'最新推荐',
  // TODO Login
    username:'nama pengguna', //'用户名',
    placeholder4:'Masukkan nama pengguna', //'请填写用户名',
    password:'sandi', //'密码',
    placeholder5:'Masukkan sandi', //'请填写密码',
    registerNow:'Mendaftar sekarang', //'立即注册',
    alreadyLoggedAccount:'Sudah memiliki akun login', //'已有登录账号',
    placeholder6:'Kode verifikasi tidak diisi atau diisi dengan salah', //'验证码未填或填写错误',
    registeredSuccessfully:'Log masuk berhasil', //'注册成功',
  // TODO Order
    myOrder:'Pembelian saya', //'我的订单',
    all:'semua', //'全部',
    due:'Pembayaran Tertunda', //'待付款',
    confirmed:'mengkonfirmasikan', //'待确认',
    waitingSent:'untuk dikirimkan', //'待发货',
    shipped:'Untuk menerima', //'已发货',
    transactionCompleted:'Transaksi Selesai', //'交易完成',
    orderTime:'Urutan waktu', //'订单时间',
  // TODO OrderDetail
    orderDetails:'Urutan rincian', //'订单详情',
    orderStatus:'Status pemesanan', //'订单状态',
    orderNumber:'ID perintah', //'订单编号',
    confirmReceipt:'Tanda terima', //'确认收货',
    goPay:'Untuk membayar', //'去支付',
    cancelOrder:'Batalkan perintah', //'取消订单',
    distributionMode:'Informasi pengiriman', //'配送方式',
    ordinaryExpress:'Pengiriman ekspres reguler', //'普通快递',
    goodsDesc:'Pengiriman gratis', //'全场包邮',
    confirmCancellation:'Konfirmasi perintah pembatalan', //'确认取消订单?',
    cancelSuccess:'Berhasil dibatalkan', //'取消成功',
    isConfirmReceipt:'Konfirmasi?', //'是否确认收货?',
    confirmSuccess:'Dikonfirmasi berhasil', //'确认成功',
    immediateEvaluation:'Evaluasi sekarang', //立即评价,
    comprehensiveEvaluation:'ringkasan', //综合评价,
    evaluationContent:'puas', //评价内容,
    evaluationContentPlaceholder:"Mari kita perluas ide produk …", //展开说说对商品的想法吧,
    photoVideo:'Foto /Video',//图片/视频,
    logisticsInfo:'Nomor pelacakan',//'物流信息',
    copy:'salin',//'复制'
  // TODO ProductDetail
    productDetails:'Perincian produk', //'商品详情',
    exemptFromPostage:'Pengiriman gratis ', //'免邮费',
    salesVolume:'terjual', //'销量',
    evaluate:'tinjauan', //'评价',
    purchased:'dibeli', //'已购',
    negativeComment:'Komentar negatif', //'差评',
    mediumReview:'Tinjauan Medium', //'中评',
    goodReputation:'Reputasi baik', //'好评',
    overviewParameter:'Parameter ikhtisar', //'概述参数',
    customerService:'obrolan', //'客服',
    addCart:'Tambah ke keranjang', //'加入购物车',
    buyNow:'Beli sekarang', //'立即购买',
    addSuccessfully:'Ditambahkan ke keranjang', //'添加成功',
  // TODO ProductList
    search:'cari', //'搜索',
    recommended:'saran', //'推荐',
    new:'terkini', //'新品',
    price:'harga', //'价格',
  // TODO Setting
    accountManagement:'Pengaturan Account', //'账号管理',
    nickname:'nama', //'昵称',
    personalSignature:'Bio', //'个性签名',
    changePassword:'Ubah sandi', //'修改密码',
    avatar:'avatar', //'头像',
    newPasswordPlaceholder:'Silahkan masukkan password baru anda', //'请输入新密码',
    save:'kirim', //'保存',
    logOut:'Keluar', //'退出登录',
  // TODO User
    mine:'Me', //'我的',
    loginName:'Username', //'登录名',
    aboutUs:'tentang', //'关于我们',
  // TODO NavBar
    home:'rumah', //'首页',
    sort:'kategori', //'分类',
  // TODO Common
    loading:'memuat', //'加载中...',
    logIn:'Log masuk', //'登录',
    signIn:'Cetak timbul', //'注册',
    noMore:'Tidak ada lagi', //'没有更多了',
    saveSuccess:'keberhasilan', //'保存成功',
    deletSuccess:'Sukses dihapusname', //'删除成功',
    placeholder:'Cari', //'请搜索您喜欢的商品',
    changeLang:'Ganti bahas(Bahasa indonesia)', //'切换语言'
  // TODO Pay
    payName:'Nama pembayaran', //'支付姓名',
    wallet:'dompet', //'钱包',
    internetbank:'e-bank', //'网银',
    cod:'Bayar saat dikirim',// 货到付款
    placeholder7:'Tolong tulis nama pembayarannya', //'请填写支付姓名',
    scanQr:'Pindai qrname',//扫码
    qris:'Qris bayar',//Qris支付
    pay:'bayar',//支付
    bankcardCode:'Kode kartu Bank',//银行卡编码
    confirmPayment:'Konfirmasi pembayaran',//确认支付
    pleaseSelectCardCode:'Pilih kode kartu',//请选择银行卡编码
    confirmCancelPayment:'Apakah anda mengkonfirmasi pembatalan pembayaran?',//确认取消支付吗？
  // TODO Share
    shareProfits:'Mendapat Bonus',//分享赚金
    invitationLink:'Tautan undanganname',//我的邀请链接
    invitationStep:'Langkah undanga',//邀请步骤
    chooseOne:'Hanya memilih satu',//以下任选其一
    tips1:'1.Salin tautan undangan di atas dan kirim ke teman-teman anda',//1.复制上面的邀请链接，发送给您的好友
    tips2:'2.Pilih salah satu poster di bawah yang ingin anda download ke ponsel anda dan dikirimkan kepada teman-teman anda',//2.选择一款下面你喜欢的海报下载到手机，可以发送给您的好友
    tips:'Tips: setelah teman-teman anda berhasil mendaftar melalui tautan undangan anda atau memindai kode poster QR anda, mereka akan secara permanen menjadi penggemar anda, dan jumlah perintah yang dihasilkan oleh mereka di masa depan akan dihargai dan secara otomatis dikreditkan ke rekening anda',//Tips：好友通过您的邀请链接或者扫描您的海报二维码成功注册后，Ta将永久成为您的粉丝，未来Ta产生的订单金额，您都有奖励，会自动计入您的账户中
    posters:'Buatlah poster',//一键生成专属海报
    saveScreenshot:'Tekan lama/simpan tangkapan layar ',//长按/截屏保存
    cancel:'batal',//关闭
    youHaveGot:'Kumulatif punya',//您已赚取
    bonus:'Bonus',//奖金


    bonusDetail:'Bonus detail',// 奖金明细
    withdrawalApplication:'Penarikan',// 提现申请
    totalContribution:'Total sumbangan',// 贡献总金额
    amountWithdrawalNotbeLess1:'Jumlah yang diterapkan untuk penarikan tidak bisa kurang dari 1',// 提现申请金额不能小于1
    withdrawalApplicationReview:'Aplikasi penarikan telah berhasil dimulai, silahkan tunggu peninjauan latar belakang',// 提现申请已发起成功，请等待后台审核
    //TODO GoogleRegister
    bindAccount:'Catatan pengikat',// 绑定账号
    bindingSuccessful:'Sukses yang mengikat',// 绑定成功
    //TODO DoTask
    moneyDoingMissions:'Misi Aan Bonus',// 做任务赚金
    doTaskCollectGift:'Apakah tugas, mendapatkan hadiah',// 做任务，薅礼金
    taskStep:'Langkah tugascomment',// 任务步骤
    icon:'ikoncomment',// 图标
    goToProductDetailsAny:'Pergi ke halaman rincian produk dari barang apapun',// 前往任何商品的详情页
    clickTopRight:'Klik di sudut kanan atas',// 点击右上角
    copyProductDetailsLink:'Salin rincian produk tautan',// 复制商品详情链接
    sendYourFriends:'Kirim ke teman anda dengan klik',// 发送给您的朋友点击
    view:'tampilan',// 查看
    canObtainCorresponding:'Dapat memperoleh apa yang dimaksud',// 即可获得相应
    rewards:'pahala',// 奖励
    taskReward:'Pahala tugascomment',// 任务奖励
    be:'jadilah',// 被
    personView:'Tampilan orang',// 人查看
    gain:'keuntungan',// 得到
    note:'Catatan: semakin banyak orang melihat tautan rincian produk',// 注：商品详情链接被查看的人数越多
    moreRewards:'Semakin banyak imbalan yang kau dapatkan',// 获得奖励就会越多
    countdownMission:'Hitung mundur ke misimu',// 您的任务倒计时
    day:'hari',// 天
    when:'kapan',// 时
    points:'poin',// 分
    seconds:'detik',// 秒
    taskProgress:'Tugas anda kemajuan',// 您的任务进度
    currentlyCompletedNumber:'Jumlah tugas saat ini selesai',// 当前任务完成数量
    notReapply:'Jangan diterapkan kembali',// 请勿重新申请
    applicationSuccessful:'Aplikasi sukses',// 申请成功
    loginSuccessful:'Log masuk sukses',// 登录成功
    googleAuthorizationFailed:'Otorisasi Google gagal, silahkan refresh dan coba lagi',// 谷歌授权失败，请刷新重试
    accumulateRewards:'Mengumpulkan imbalan',// 累计得到奖励
    bonusBalance:'saldo',// 奖金余额
    accumulatedBonus:'Banyak',// 累计奖金
    withdrawing:'Dalam Penarikan',// 提现中
    sureWithdrawal:'Apakah anda yakin untuk mengajukan penarikan seluruh saldo bonus?',// 确定将全部奖金余额发起提现申请？

    name:'nama',//姓名
    enteryourname:'Masukkan nama anda',//请输入姓名
    telephone:'telepon',//电话
    enteryourtelephone:'Masukkan nomor telepon',//请输入姓名
    address:'alamat',//地址
    selectaddress:'Pilih alamat',//请选择地址
    detailedaddress:'Alamat terperinci',//详细地址
    enterfulladdress:'Masukkan alamat lengkapnya',//请输入详细地址
    setdefaultaddress:'Atur alamat standar',//设为默认地址
    noteaddress:'Catatan: Alamat saat ini',//注：当前地址
    support:'Dukungan',//支持
    nonsupport:'Nonsupport',//不支持
    cashondelivery:'Tunai saat pengiriman (COD)',//货到付款（COD）
    total:'Jumlah',//合计
    freight:'Muatan',//运费
    currentshippingcosts:'Biaya pengiriman saat ini adalah ::',//当前运费为：
    rememberCourier:'Tolong ingat untuk memberikan pembayaran kepada kurir ~',//请记得连同货款交付给快递员哦~
    delete:'hapus',//删除

    tip:'Tips',//提示
    payCloseTip:'Sistem mendeteksi bahwa pembayaran Anda saat ini tidak berhasil. Kegagalan mengambil tangkapan layar dan menutup jendela ini dapat mengakibatkan kegagalan pembayaran. Apakah Anda ingin menutupnya?',//系统检测到您当前还未支付成功，未截图且关闭此窗口可能导致支付失败，是否关闭?
    sure:'Yakin',//确定
    paymentSuccess:'Pembayaran Sukses',//支付成功

    firstSingleMinus1:'Dapatkan diskon instan', //首单立减2000 ！！！
    firstSingleMinus2:'pada pesanan pertama Anda! ! !', //首单立减2000 ！！！
    theFirstOrder1:'Jika pertama kali berhasil melakukan pemesanan, Anda akan mendapatkan hadiah sebesar ',//首次下单成功，即可获得2000
    theFirstOrder2:'. Setelah pesanan berhasil dilakukan, Anda dapat mengambilnya di 【Me】. Segera lakukan pemesanan~', //奖励，下单成功后，可在个人中心领取，赶紧下单吧~
    congratulationOrder:'Selamat, pesanan Anda berhasil dilakukan! ! !', //恭喜您，下单成功！！！
    youWillReceive:'Anda akan mendapatkan keuntungan sebagai berikut', //您将获得以下权益
    aSuccessful1:'Berhasil memperoleh ', //成功
    aSuccessful2:' hadiah, yang nanti bisa dilihat di 【Me】.', //获得2000奖励，稍后可在个人中心查看
    youCanShare1:'Anda dapat membagikan tautan ini dengan teman Anda untuk melakukan pemesanan, dan dia akan menerima hadiah ', //可分享此链接给您朋友下单，他将获得
    youCanShare2:', dan Anda juga akan menerima komisi yang sesuai. Untuk detailnya, lihat 【Me】 【Mendapat Bonus】', //2000奖励，以及您也会获得相应佣金，详见个人中心【分享赚金】
    youCanShareView:'Anda dapat membagikan tautan ini kepada teman-teman Anda untuk melihatnya. Semakin banyak orang yang melihatnya, Anda juga akan mendapatkan komisi hadiah menonton yang sesuai. Untuk detailnya, silakan lihat 【Me】 【Misi Aan Bonus】', //可分享此链接给您朋友查看，查看得人越多，你也会获得相应的查看奖励佣金，详见个人中心【做任务赚金】
    goToPersonal:'Pergi ke 【Me】', //前往个人中心
    copyLink:'Salin tautan', //复制链接
    systemAssigns:'Sistem membuat akun', //系统分配账号密码

    theServer:'Server tidak normal, harap hubungi admin!',// 服务端异常，请联系管理员！
    automaticLogin:'masuk otomatis',// 自动登录
    theSystem:'Sistem mendeteksi bahwa Anda saat ini tidak masuk dan secara otomatis menetapkan akun untuk Anda, atau Anda sudah masuk/mendaftar dengan sebuah akun.',// 系统检测您当前未登录，已自动为您分配账号，或者已有账号自行登录/注册。
    noteAutonomous:'Catatan: Akun dan kata sandi yang ditetapkan dapat diubah di pusat pribadi',// 注：分配账号以及密码可在个人中心修改
    autonomousLogin:'Login/registrasi otomatis',// 自主登录/注册
    assignedAccount :'Tetapkan akun untuk masuk',// 分配账号登录
    noteRemember:'Catatan: Ingatlah untuk menyalin dan menyimpannya sendiri. Nanti Anda dapat mengubah akun dan kata sandi Anda di pusat pribadi dan menerima hadiah!',// 注：记得复制后自行保存哦，后续可在个人中心修改账号和密码以及领取奖励！
    successfulReplication:'Salin berhasil',// 复制成功
  },
}


export default id;

