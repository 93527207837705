
<template>
  <div class="seting-box">
    <s-header :name="$t('t.accountManagement')"></s-header>
    <div class="input-item">
      <van-field v-model="nickName" :label="$t('t.nickname')" />
      <van-field v-model="introduceSign" :label="$t('t.personalSignature')" />
      <van-field v-model="password" type='password' :placeholder="$t('t.newPasswordPlaceholder')" :label="$t('t.changePassword')" />
      <van-field name="uploader" :label="$t('t.avatar')">
        <template #input>
          <van-uploader v-model="headSculptureImage" :max-count="1" :after-read="afterRead" />
        </template>
      </van-field>
    </div>
    <van-button class="save-btn" type="primary" @click="save" block>{{$t('t.save')}}</van-button>
    <van-button class="save-btn" type="danger" @click="logout" block>{{$t('t.logOut')}}</van-button>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { getUserInfo, EditUserInfo, logout,uploadImg } from '../service/user'
import { setLocal } from '@/common/js/utils'

export default {
  components: {
    sHeader
  },
  data() {
    return {
      nickName: '',
      introduceSign: '',
      password: '',
      headSculptureImage:[],
    }
  },
  async mounted() {
    const { data } = await getUserInfo()
    this.nickName = data.nickName
    this.introduceSign = data.introduceSign
    this.headSculptureImage = data.headSculptureImage?[{url:data.headSculptureImage}]:[]
  },
  methods: {
    async save() {
      const params = {
        introduceSign: this.introduceSign,
        nickName: this.nickName,
        headSculptureImage:this.headSculptureImage.length?this.headSculptureImage[0].url:'',
        passwordMd5: this.password?this.$md5(this.password):null
      }
      const { data } = await EditUserInfo(params)
      this.$toast.success(this.$t('t.saveSuccess'))
      this.$router.push({ path: '/user' })
    },
    async logout() {
      const { resultCode } = await logout()
      if (resultCode == 200) {
        setLocal('token', '')
        window.location.href = '/'
      }
    },
    afterRead(file) {
      // file.status = 'uploading';
      // file.message = '上传中...';
      let formData = new FormData();
      formData.append("file", file.file);
      uploadImg(formData).then(res => {
        if (res.resultCode == 200) {
          this.headSculptureImage = [{url:res.data}]
        }
      })
      // setTimeout(() => {
      //   file.status = 'failed';
      //   file.message = '上传失败';
      // }, 1000);
    },
  }
}
</script>

<style lang="less" scoped>
  .seting-box {
    .input-item {
      margin-top: 44px;
    }
    .save-btn {
      width: 80%;
      margin: 20px auto ;
    }
  }
</style>
