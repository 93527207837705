
import Vue from 'vue'
import md5 from 'js-md5';
import App from './App.vue'
import router from './router'
import store from './store'
import { prefix } from '@/common/js/utils'
import Vant from 'vant';
import 'lib-flexible/flexible'
import 'vant/lib/index.less'// 切记：为了能够覆盖默认的 less 变量，这里一定要把后缀名改为 .less
// 多语言
import {i18n, vantLocales} from './lang'
// import i18n from './lang'
// vant-ui组件国际化
vantLocales(i18n.locale)
Vue.use(Vant)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$md5 = md5;
Vue.prototype.prefix = prefix;

Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
}
Vue.filter('wq_mFormat', (value,dw) => {
  //dw 货币单位预留字段
  if(!dw) dw = '￥ ';
  if(value === null || value === undefined || value === '') value = 0;
  value = value.toString();
  if(value.length<=3){
    return dw+value
  }else{
    return dw+value.slice(0,-3)+','+value.slice(-3);
  }
  // if(!value.split(".")[1]){
  //   return dw+value.replace(/\d{1,3}(?=(\d{3})+(?:\.\d{1,2})?$)/g, '$&.') + '';
  // }else if(value.split(".")[1].length == 1){
  //   return dw+value.replace(/\d{1,3}(?=(\d{3})+(?:\.\d{1,2})?$)/g, '$&.') + '';
  // }else{
  //   return dw+value.replace(/\d{1,3}(?=(\d{3})+(?:\.\d{1,2})?$)/g, '$&.');
  // }
});
