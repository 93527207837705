
<template>
  <div class="categray">
    <div>
      <header class="category-header wrap" :style="{top:firstOrderReward?'40px':0}">
        <!--<i class="nbicon nbmenu2" @click="menuBool = true"></i>-->
        <i class="nbicon nbfanhui" @click="goHome"></i>
        <div class="header-search" @click="$router.push({ path:'/product-list?from=category'})">
          <i class="nbicon nbSearch"></i>
          <router-link tag="span" class="search-title" to="./product-list?from=category">{{$t('t.placeholder')}}</router-link>
        </div>
        <i class="iconfont icon-More"></i>
      </header>
      <nav-bar></nav-bar>
      <div class="search-wrap" ref="searchWrap">
        <list-scroll :scroll-data="categoryData" class="nav-side-wrapper">
          <ul class="nav-side">
            <li
              v-for="item in categoryData"
              :key="item.categoryId"
              v-text="item.categoryName"
              :class="{'active' : categoryId == item.categoryId}"
              @click="getGoods(item.categoryId)"
            ></li>
          </ul>
        </list-scroll>
        <div class="search-content">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="product-list-refresh height100 overflowY">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="$t('t.noMore')"
              @load="onLoad"
              @offset="300">
              <!-- <p v-for="item in list" :key="item">{{ item }}</p> -->
              <div class="product-item" v-for="(item, index) in productList" :key="index" @click="productDetail(item)">
                <img :src="JSON.parse(item.goodsCoverImg)[0]" />
                <div class="product-info">
                  <!--<p class="name">{{item.goodsName}}</p>-->
                  <p class="name">{{item.goodsName}}</p>
                  <p class="subtitle">{{item.goodsIntro}}</p>
                  <van-row type="flex" align="center" justify="space-between">
                    <van-row class="price">{{item.sellingPrice | wq_mFormat}}</van-row>
                    <van-row v-if="item.salesVolume">{{$t('t.salesVolume')}}{{item.salesVolume}}</van-row>
                  </van-row>

                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </div>
    </div>
    <van-row class="color24 size14 paddBott60 paddTop30 textCenter">深圳市单单不一科技&nbsp;&nbsp;2024版权所有&nbsp;
      <a href="https://beian.miit.gov.cn/" class="colorBlue" target="_self">粤ICP备2024206707号</a>
    </van-row>
    <van-popup v-model="menuBool"  position="left" class="height100 leftBox colorF overflowY" get-container="body" :safe-area-inset-bottom="true">
      <van-row class="size20 padding15 borderBott6c fontB pointer textCenter borderButtE4" @click="$router.push('/home')">牧盛商城</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/home')">首页</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/category')">分类</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/afterSales')">售后服务</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/refundInstructions')">退款说明</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/returnProcess')">退货流程</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/contactUs')">联系我们</van-row>
    </van-popup>
  </div>
</template>

<script>
import navBar from '@/components/NavBar'
import listScroll from '@/components/ListScroll'
import { getCategory,search } from "../service/good";
import { addCart } from '../service/cart'
import {selectConfig } from '../service/user'
export default {
  components: {
    navBar,
    listScroll
  },
  data() {
    return {
      menuBool: false,
      categoryData: [],
      currentIndex: 15,
      categoryId:'',

      refreshing: false,
      loading: false,
      finished: false,
      productList: [],
      totalPage: 0,
      page: 1,
      firstOrderReward:''
    }
  },
  async created(){
    const { data, resultCode } = await selectConfig()
    this.firstOrderReward = data.taskConfigParam.firstOrderReward
    document.getElementById('linkIcon').href = data.logoImage
    document.title = data.projectName
  },
  async mounted() {
    this.setWrapHeight()
    await getCategory({ categoryLevel:1,parentId:0, pageNumber: 1,pageSize:100}).then(res=>{
      this.categoryData = res.data.list
      this.categoryId = res.data.list[0].categoryId
      this.onRefresh()
    })
  },
  methods: {
    getGoods(categoryId) {
      this.categoryId = categoryId
      this.onRefresh()
    },
    async init() {
      if(!this.categoryId) return
      const { data, data: { list } } = await search({ pageNumber: this.page, goodsCategoryId: this.categoryId})
      this.productList = this.productList.concat(list)
      this.totalPage = data.totalPage
      this.loading = false;
      if (this.page >= data.totalPage) this.finished = true
    },
    onLoad() {
      if (!this.refreshing && this.page < this.totalPage) {
        this.page = this.page + 1
      }
      if (this.refreshing) {
        this.productList = [];
        this.refreshing = false;
      }
      this.init()
    },
    onRefresh() {
      this.refreshing = true
      this.finished = false
      this.loading = true
      this.page = 1
      this.onLoad()
    },
    productDetail(item) {
      this.$router.push({ path: `product/${item.goodsId}` })
    },


    goHome () {
      this.$router.push({ path: 'home' })
    },
    setWrapHeight() {
      // 设置视口高度
      let $screenHeight = document.documentElement.clientHeight
      this.$refs.searchWrap.style.height = $screenHeight - 100 + 'px'
    },
    async addCart(goodsCount,goodsId) {
      const { data, resultCode } = await addCart({goodsCount,goodsId })
      if (resultCode == 200 ) this.$toast.success(this.$t('t.addSuccessfully'))
      this.$store.dispatch('updateCart')
    },

    selectMenu(index) {
      this.currentIndex = index
    },
    selectProduct(item){
      this.$router.push({ path: `product-list?categoryId=${item.categoryId}` })
    },
  }
}
</script>
<style lang="less" scoped>
  @import '../common/style/mixin';
  .categray {
    padding-bottom: 60px;
    .category-header {
      background: #000;
      position: fixed;
      left: 0;
      .fj();
      .wh(100%, 50px);
      line-height: 50px;
      padding: 0 15px;
      box-sizing: border-box;
      font-size: 15px;
      color: #656771;
      z-index: 10;
      &.active {
        background: @primary;
      }
      .icon-left {
        font-size: 25px;
        font-weight: bold;
      }
      .header-search {
        display: flex;
        width: 80%;
        height: 20px;
        line-height: 20px;
        margin: 10px 0;
        padding: 5px 0;
        color: #232326;
        background: #F7F7F7;
        border-radius: 20px;
        .nbSearch {
          padding: 0 10px 0 20px;
          font-size: 17px;
        }
        .search-title {
          font-size: 12px;
          color: #666;
        }
      }
      .icon-More {
        font-size: 20px;
      }
    }
  }
  .search-wrap {
    .fj();
    width: 100%;
    margin-top: 50px;
    background: #F8F8F8;
    border-top: 1px solid #999;
    .nav-side-wrapper {
      width: 28%;
      height: 100%;
      /*overflow: hidden;*/
      .nav-side {
        width: 100%;
        .boxSizing();
        background: #F8F8F8;
        li {
          width: 100%;
          /*height: 56px;*/
          text-align: center;
          /*line-height: 56px;*/
          font-size: 14px;
          padding: 15px 0;
          &.active {
            color: #fff;//@primary;
            background: #000;
          }
        }
      }
    }
    .search-content {
      width: 72%;
      height: 100%;
      padding: 0 10px;
      background: #fff;
      .boxSizing();
      .swiper-container {
        width: 100%;
        .swiper-slide {
          width: 100%;
          .category-main-img {
            width: 100%;
          }
          .category-list {
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 0;
            width: 100%;
            .catogory-title {
              width: 100%;
              font-size: 17px;
              font-weight: 500;
              padding: 5px 0;
            }
            .product-item {
              width: 33.3333%;
              margin-bottom: 10px;
              text-align: center;
              font-size: 15px;
              .product-title{ padding: 5px 0;}
              .product-img {
                .wh(40px, 40px);
              }
            }
          }
        }
      }
    }
  }
  .fade-out-enter-active, .fade-out-leave-active {
    // transition: opacity 0.5s;
  }

  .fade-out-enter, .fade-out-leave-to {
    // opacity: 0;
  }
  .product-list-refresh {
    .product-item {
      .fj();
      width: 100%;
      /*height: 120px;*/
      padding: 10px 0;
      border-bottom: 1px solid #dcdcdc;
      img {
        width: 90px;
        height: 90px;
        /*padding: 0 10px;*/
        padding-left: 10px;
        .boxSizing();
      }
      .product-info {
        /*width: 56%;*/
        flex: 1;
        /*height: 120px;*/
        padding: 5px 10px;
        text-align: left;
        .boxSizing();
        p {
          margin: 0
        }
        .name {
          width: 100%;
          /*max-height: 40px;*/
          line-height: 20px;
          font-size: 15px;
          color: #333;
          /*overflow: hidden;*/
          /*text-overflow:ellipsis;*/
          /*white-space: nowrap;*/
        }
        .subtitle {
          width: 100%;
          /*max-height: 20px;*/
          padding: 10px 0;
          line-height: 25px;
          font-size: 13px;
          color: #999;
          /*overflow: hidden;*/
        }
        .price {
          color: @theme;
          font-size: 16px;
        }
      }
    }
  }
</style>
