
<template>
  <van-row type="flex" id="app" class="flexDirection height100">
    <van-notice-bar
      v-if="firstOrderReward"
      @click="firstRewardsFn"
      class="app noticeBox"
      color="#e92d74"
      scrollable
      left-icon="volume-o"
      :text="$t('t.firstSingleMinus1')+firstOrderReward+$t('t.firstSingleMinus2') + $t('t.firstSingleMinus1')+firstOrderReward+$t('t.firstSingleMinus2') + $t('t.firstSingleMinus1')+firstOrderReward+$t('t.firstSingleMinus2')">
    </van-notice-bar>
    <transition :name="transitionName">
      <router-view class="router-view flex1 " :class="{'paddTop40':firstOrderReward}" />

    </transition>

  </van-row>
</template>

<script>
  import {selectConfig } from './service/user'
  export default {
    data () {
      return {
        transitionName: 'slide-left',
        firstOrderReward:''
      }
    },
    async created(){
      const { data, resultCode } = await selectConfig()
      document.getElementById('linkIcon').href = data.logoImage
      document.title = data.projectName
      this.firstOrderReward = data.taskConfigParam.firstOrderReward

    },
    watch: {
      $route(to, from) {
        // 有主级到次级
        if (to.meta.index > from.meta.index) {
          this.transitionName = 'slide-left' // 向左滑动
        } else if (to.meta.index < from.meta.index) {
          // 由次级到主级
          this.transitionName = 'slide-right'
        } else {
          this.transitionName = ''   //同级无过渡效果
        }
      }
    },
    methods:{
      firstRewardsFn(){
        this.$dialog.alert({
          message: this.$t('t.theFirstOrder1')+this.firstOrderReward+this.$t('t.theFirstOrder2'),
        }).then(() => {
          // on close
        });
      }
    },
  }
</script>

<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

.router-view{
    width: 100%;
    height: auto;
    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
    margin: 0 auto;
    -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active{
    height: 100%;
    will-change: transform;
    transition: all 500ms;
    position: absolute;
    backface-visibility: hidden;
}
.slide-right-enter{
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active{
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-enter{
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active{
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}
  .paddBott60{padding-bottom: 60px}
</style>
