<template>
  <div class="address-box">
    <s-header :name="$t('t.addressManagement')" :back="from?'/user':''"></s-header>
    <div class="address-item">
      <van-address-list
        v-model="chosenAddressId"
        :list="list"
        :default-tag-text="$t('t.acquiesce')"
        @add="onAdd"
        @edit="onEdit"
        @select="select"
      />
    </div>
  </div>
</template>

<script>

import sHeader from '@/components/SimpleHeader'
import { getAddressList } from '../service/address'
export default {
  components: {
    sHeader
  },
  data() {
    return {
      chosenAddressId: '1',
      list: [],
      from: this.$route.query.from,
    }
  },
  async mounted() {
    const { data } = await getAddressList()
    this.list = data.map(item => {
      return {
        id: item.addressId,
        name: item.userName,
        tel: item.userPhone,
        address: `${item.provinceName} ${item.cityName} ${item.regionName} ${item.detailAddress}`,
        isDefault: !!item.defaultFlag
      }
    })
    this.displayRadioFn()
  },
  methods: {
    displayRadioFn(){
      this.$nextTick(() => {
        let radioArr = document.getElementsByClassName('address-item')[0].getElementsByClassName('van-radio__icon')
        if(radioArr && radioArr.length && this.from == 'mine'){
          for(var i = 0; i <radioArr.length;i++) {
            radioArr[i].style.display = "none";
          }
        }
      })

    },
    onAdd() {
      this.$router.push({ path: `address-edit?type=add&from=${this.from}` })
    },
    onEdit(item, index) {
      this.$router.push({ path: `address-edit?type=edit&addressId=${item.id}&from=${this.from}` })
    },
    select(item, index) {
      if(this.from != 'mine'){
        this.$router.push({ path: `create-order?addressId=${item.id}&from=${this.from}` })
      }
    }
  }
}
</script>

<style lang="less">
  @import '../common/style/mixin';
  .address-box {
    .van-radio__icon {
      /*display: none !important;*/
    }
    .address-item {
      margin-top: 44px;
      .van-button {
        background: @primary;
        border-color: @primary;
      }
    }
  }
</style>
