const enID = {
  name: 'nama ',
  tel: 'telepon ',
  save: 'selamatkan ',
  confirm: 'konfirmasi ',
  cancel: 'batalkan ',
  delete: 'hapus ',
  complete: "Lengkap",
  loading: 'Loading … ',
  telEmpty: 'silakan isi di tel',
  nameEmpty: 'tolong isi nama ',
  nameInvalid: 'nama cacat ',
  confirmDelete: 'apakah kamu yakin ingin menghapusnya? ',
  telInvalid: 'nomor telepon rusak ',
  vanCalendar: {
    end: 'selesai ',
    start: 'mulai ',
    title: 'kalender ',
    startEnd: 'awal/akhir ',
    weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthTitle: function monthTitle(year, month) {
      return year + "/" + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "Memilih tidak lebih dari " + maxRange + " hari";
    }
  },
  vanCascader: {
    select: 'pilih'
  },
  vanContactCard: {
    addText: 'tambah info kontak'
  },
  vanContactList: {
    addText: 'tambah kontak baru'
  },
  vanPagination: {
    prev: 'sebelumnya ',
    next: 'berikutnya'
  },
  vanPullRefresh: {
    pulling: 'tarik untuk menyegarkan … ',
    loosing: "Loose untuk refresh …"
  },
  vanSubmitBar: {
    label: 'Total:'
  },
  vanCoupon: {
    unlimited: 'tak terbatas ',
    discount: function discount(_discount) {
      return _discount * 10 + "% off";
    },
    condition: function condition(_condition) {
      return "setidaknya " + _condition;
    }
  },
  vanCouponCell: {
    title: 'kupon ',
    tips: 'tidak ada kupon ',
    count: function count(_count) {
      return "Kau punya " + _count + " kupon";
    }
  },
  vanCouponList: {
    empty: 'tidak ada kupon ',
    exchange: 'pertukaran ',
    close: 'dekat ',
    enable: 'tersedia ',
    disabled: 'tersedia ',
    placeholder: 'kode kupon'
  },
  vanAddressEdit: {
    area: 'daerah ',
    postal: 'pos ',
    areaEmpty: 'silakan pilih daerah penerima ',
    addressEmpty: 'alamat tidak dapat kosong ',
    postalEmpty: 'kode pos yang salah ',
    defaultAddress: 'atur sebagai alamat standar ',
    telPlaceholder: 'telepon ',
    namePlaceholder: 'nama ',
    areaPlaceholder: 'daerah'
  },
  vanAddressEditDetail: {
    label: 'alamat ',
    placeholder: 'alamat'
  },
  vanAddressList: {
    add: 'tambah alamat baru'
  }
};
export default enID;














































