
<template>
  <header class="simple-header" :style="{top:firstOrderReward?'40px':0}">
    <i v-if="isMenu" class="nbicon nbmenu2" @click="menuBool = true"></i>
    <i v-else class="nbicon nbfanhui" @click="goBack"></i>
    <div class="simple-header-name">{{ name }}</div>
    <van-icon @click="shareLink" v-if="goodsId && userId" name="link-o" class="size20 lineH15 paddTop10 colorW fontB" />
    <i v-else></i>
    <!--<i class="nbicon nbmore"></i>-->
    <van-popup v-model="menuBool"  position="left" class="height100 leftBox colorF overflowY" get-container="body" :safe-area-inset-bottom="true">
      <van-row class="size20 padding15 borderBott6c fontB pointer textCenter borderButtE4" @click="$router.push('/home')">牧盛商城</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/home')">首页</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/category')">分类</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/afterSales')">售后服务</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/refundInstructions')">退款说明</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/returnProcess')">退货流程</van-row>
      <van-row class="size12 padding15 borderBott6c fontB pointer" @click="$router.push('/contactUs')">联系我们</van-row>
    </van-popup>
  </header>
</template>

<script>
  import { getUserInfo,selectConfig } from '../service/user'
  import { getLocal } from '@/common/js/utils'
  import { doCopy } from '@/common/js/utils'
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    back: {
      type: String,
      default: ''
    },
    isMenu: {
      type: Boolean,
      default: false
    },
    goodsId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      swiperList: [],
      menuBool: false,
      openShare:'',
      userId:'',
      firstOrderReward:'',
    }
  },
  async created(){
    const { data, resultCode } = await selectConfig()
    this.firstOrderReward = data.taskConfigParam.firstOrderReward
    document.getElementById('linkIcon').href = data.logoImage
    document.title = data.projectName
  },
  async mounted() {
    const token = getLocal('token')
    if (token) {
      const { data } = await getUserInfo()
      this.openShare = data.openShare
      this.userId = data.userId
    }
  },
  methods: {
    shareLink(){
      this.$dialog.alert({
        title: '分享商品任务',
        message: '点击复制按钮，即可复制商品详情链接，发送给您的朋友点击查看，即可获得相应奖励！！！',
        confirmButtonText:"点击复制"
      }).then(() => {
        let urlStr = `https://${window.location.host}/#/product/${this.goodsId}?shareId=${this.userId}`
        doCopy(urlStr)
      });
    },
    goBack() {
      if (!this.back) {
        this.$router.go(-1)
      } else {
        this.$router.push({ path: this.back })
      }
      this.$emit('callback')
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../common/style/mixin';
  .simple-header {
    position: fixed;
    left: 0;
    z-index: 10;
    .fj();
    .wh(100%, 44px);
    line-height: 44px;
    padding: 0 10px;
    .boxSizing();
    color: #fff;
    background: #000;
    /*border-bottom: 1px solid #dcdcdc;*/
    .simple-header-name {
      font-size: 14px;
    }
  }
  .leftBox{
    width: 250px;
    background: #000;
    height: 100%;
  }
</style>
