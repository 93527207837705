import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {Locale} from 'vant'
// import VantUI from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import thTH from 'vant/lib/locale/lang/th-TH'
import enID from './en-ID'

import enLocale from './en'
import zhLocale from './cn'
import idLocale from './id'
import thLocale from './th'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  },
  id: {
    ...enID,
    ...idLocale
  },
  th: {
    ...thTH,
    ...thLocale
  }
}

const i18n = new VueI18n({
  locale: localStorage['lang'] || 'zh',  // 设置默认语言
  messages: messages // 设置资源文件对象
})
// Vue.use(VantUI, {
//   i18n: (key, value) => i18n.t(key, value)
// })
// 更新vant组件库本身的语言变化，支持国际化
function vantLocales (lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'zhCN') {
    Locale.use('zh', zhCN)
  }else if (lang === 'id') {
    Locale.use(lang, enID)
  }else if (lang === 'th') {
    Locale.use(lang, thTH)
  }
}

export {i18n, vantLocales}
// export default i18n
