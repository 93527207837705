
import axios from '../utils/axios'

export function getUserInfo() {
  return axios.get('/user/info');
}

export function EditUserInfo(params) {
  return axios.put('/user/info', params);
}

export function login(params) {
  return axios.post('/user/login', params);
}
export function autoRegister(params) {
  return axios.post('/auto/register', params);
}

export function logout() {
  return axios.post('/user/logout')
}

export function register(params) {
  return axios.post('/user/register', params);
}

export function uploadImg(params) {
  return axios.post('/upload/file', params);
}
export function selectConfig() {
  return axios.get('/selectConfig');
}
export function bindGoogle(params) {
  return axios.post('/binding/google', params);
}
export function googleLogin(params) {
  return axios.post('/googleLogin', params);
}
export function getCommissionList(params) {
  return axios.get('/user/commission/list',{ params });
}
export function getCommissionOrderList(params) {
  return axios.get('/commission/order',{ params });
}

export function taskSelect(params) {
  return axios.get('/task/select',{ params });
}
export function taskWithdrawal(params) {
  return axios.post('/apply/task/withdrawal',params);
}

export function withdrawalFn(params) {
  return axios.get('/withdrawal/commission',{ params });
}
