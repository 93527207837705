<!--
	Created by xiaoqiang on 2023/10/28.
-->
<template>
  <van-popup
    v-model="googleRegisterBool"
    :close-on-click-overlay="false"
    :style="{ width: '80%' }">
    <van-row type="flex" align="center" justify="space-between" class="padding20 size16 borderButtE4">
      <van-row class="tle fontB">{{$t('t.bindAccount')}}</van-row>
      <van-icon name="close" @click="closeFn"/>
    </van-row>
    <van-form @submit="onSubmit">
      <van-field
        v-model="ruleForm.loginName"
        name="loginName"
        :label="$t('t.username')"
        :placeholder="$t('t.username')"
        :rules="[{ required: true, message: $t('t.placeholder4')}]"
      />
      <van-field
        v-model="ruleForm.password"
        type="password"
        name="password"
        :label="$t('t.password')"
        :placeholder="$t('t.password')"
        :rules="[{ required: true, message: $t('t.placeholder5') }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" color="#e92d74" native-type="submit">{{$t('t.save')}}</van-button>
      </div>
    </van-form>
  </van-popup>

</template>

<script>
  import {register } from '../service/user'
  import { setLocal, getLocal,getQueryString } from '@/common/js/utils'
  export default {
    props:['invitationCode','googleEmail','googleRegisterBool'],
    name: "pay",
    components: {},
    data() {
      return {
        ruleForm:{
          loginName: '',
          password: '',
          invitationCode: '',
          googleEmail: '',
        },
      };
    },
    watch: {
      googleEmail:{
        handler(item) {
          this.ruleForm.googleEmail = item
        },
        immediate: true,
        deep: true
      }
    },//监听
    created() {
      this.ruleForm.invitationCode = this.invitationCode
      this.ruleForm.googleEmail = this.googleEmail
    },
    mounted() {

    },
    methods: {
      async onSubmit(values) {
        this.$toast.loading({ message: this.$t('t.loading'), forbidClick: true });
        const { data } = await register(this.ruleForm)
        this.$toast.success(this.$t('t.registeredSuccessfully'))
        setLocal('token', data)
        this.$emit('googleRegisterBackFn')
        window.location.href = '/'
      },
      closeFn(){
        this.$emit('googleRegisterBackFn')
      },
    },
    //生命周期 - 更新之后",数据模型已更新,html模板已更新
    updated() {

    },
  }
</script>

<style scoped>
  .width100{width: 90px}
  .dropdownBox /deep/ .van-dropdown-menu__bar{
    box-shadow: none;
  }
  .dropdownBox /deep/ .van-dropdown-menu__item{
    justify-content: flex-start;
  }
  .posiBott{
    position: absolute;
    bottom: 10px;
  }
</style>
