/*cn.js*/

const th = {
  t:{
  // TODO Address
    addressManagement:'ที่อยู่ของฉัน', //'地址管理',
    acquiesce:'ค่าปริยาย', //'默认',
  // TODO AddressEdit
    addAddress:'เพิ่ม ที่อยู่ใหม่', //'新增地址',
    editAddress:'แก้ไข', //'编辑地址',
  // TODO Cart
    shoppingCart:'รถเข็นช้อปปิ้ง', //'购物车',
    settleAccount:'ดูนี่สิ', //'结算',
    selectAll:'ทั้งหมด', //'全选',
    cartEmpty:'อุ๊ยรถเข็นช้อปปิ้งของเธอว่า งเปล่า', //'购物车空空空如也',
    goHome:'ไปซื้อของเดี๋ยวนี้ ละ', //'前往首页',
    editing:'การแก้ไข', //'修改中...',
    placeholder1:'คุณยังไม่ได้เลือกรายการใด ๆ สำหรับการเช็คเอาท์', //'请选择商品进行结算',
  // TODO CreateOrder
    generateOrder:'สร้างลำดับ', //'生成订单',
    commodityAmount:'รวม', //'商品金额',
  // TODO Home
    newProduct:'ล่าสุด', //'新品上线',
    hotCommodity:'ยอดขายสูงสุด', //'热门商品',
    latestRecommendation:'ความเกี่ยวข้อง', //'最新推荐',
  // TODO Login
    username:'ชื่อ', //'用户名',
    placeholder4:'ป้อนชื่อผู้ใช้', //'请填写用户名',
    password:'รหัสผ่าน', //'密码',
    placeholder5:'ป้อนรหัสผ่าน', //'请填写密码',
    registerNow:'ลงทะเบียนเดี๋ยวนี้', //'立即注册',
    alreadyLoggedAccount:'ล็อกอินด้วยรหัสผ่าน', //'已有登录账号',
    placeholder6:'รหัสการตรวจสอบยังไม่ถูกเติม หรือเติมอย่างไม่ถูกต้อง', //'验证码未填或填写错误',
    registeredSuccessfully:'การล็อกอินสำเร็จแล้ว', //'注册成功',
  // TODO Order
    myOrder:'การซื้อของฉัน', //'我的订单',
    all:'ทั้งหมด', //'全部',
    due:'เพื่อจ่าย', //'待付款',
    confirmed:'ยืนยัน', //'待确认',
    waitingSent:'ที่จะส่ง', //'待发货',
    shipped:'ที่จะรับ', //'已发货',
    transactionCompleted:'ผู้ล่วงละเมิด', //'交易完成',
    orderTime:'เวลา ที่สั่ง', //'订单时间',
  // TODO OrderDetail
    orderDetails:'รายละเอียดลำดับ', //'订单详情',
    orderStatus:'สถานะลำดับ', //'订单状态',
    orderNumber:'หมายเลขลำดับ', //'订单编号',
    confirmReceipt:'ยืนยันใบเสร็จนะคะ', //'确认收货',
    goPay:'เพื่อจ่าย', //'去支付',
    cancelOrder:'ยกเลิกคำสั่ง', //'取消订单',
    distributionMode:'ข้อมูลการจัดส่ง', //'配送方式',
    ordinaryExpress:'จัดส่งแบบเร่งด่วนปกติ', //'普通快递',
    goodsDesc:'จัดส่งฟรี', //'全场包邮',
    confirmCancellation:'ยืนยันการยกเลิกการสั่งซื้อ', //'确认取消订单?',
    cancelSuccess:'ยกเลิกเรียบร้อยแล้ว', //'取消成功',
    isConfirmReceipt:'ยืนยันใบเสร็จนะคะ', //'是否确认收货?',
    confirmSuccess:'ยืนยันเรียบร้อยแล้ว', //'确认成功',
    immediateEvaluation:'การประเมินผลตอนนี้', //立即评价,
    comprehensiveEvaluation:'การจัด อันดับ', //综合评价,
    evaluationContent:'เนื้อหา', //评价内容,
    evaluationContentPlaceholder:"ลองขยายแนวคิดของผลิตภัณฑ์...", //展开说说对商品的想法吧,
    photoVideo:'รูปภาพ/วิดีโอ',//图片/视频,
    logisticsInfo:'หมายเลขการติดตาม',//'物流信息',
    copy:'คัดลอก',//'复制'
  // TODO ProductDetail
    productDetails:'รายละเอียดผลิตภัณฑ์', //'商品详情',
    exemptFromPostage:'จัดส่งฟรี',//'免邮费 顺丰快递',
    salesVolume:'ขายแล้ว', //'销量',
    evaluate:'ตรวจสอบ', //'评价',
    purchased:'ซื้อแล้ว', //'已购',
    negativeComment:'ความคิดเห็นเชิงลบ', //'差评',
    mediumReview:'รีวิวปานกลาง', //'中评',
    goodReputation:'ชื่อเสียง ที่ดี', //'好评',
    overviewParameter:'พารามิเตอร์ภาพรวม', //'概述参数',
    customerService:'คุย', //'客服',
    addCart:'เพิ่มไปยังรถเข็น', //'加入购物车',
    buyNow:'ซื้อเลยค่ะ', //'立即购买',
    addSuccessfully:'เพิ่มไปยังรถเข็น', //'添加成功',
  // TODO ProductList
    search:'ค้นหา', //'搜索',
    recommended:'แนะนำค่ะ', //'推荐',
    new:'ล่าสุด', //'新品',
    price:'ราคา', //'价格',
  // TODO Setting
    accountManagement:'ตั้งค่าบัญชี', //'账号管理',
    nickname:'ชื่อ', //'昵称',
    personalSignature:'ชีววิทยา', //'个性签名',
    changePassword:'เปลี่ยนรหัสผ่าน', //'修改密码',
    avatar:'อวตาร', //'头像',
    newPasswordPlaceholder:'โปรดป้อนรหัสผ่านใหม่ของคุณ', //'请输入新密码',
    save:'บันทึก', //'保存',
    logOut:'ออกจากระบบ', //'退出登录',
  // TODO User
    mine:'ฉันเอง', //'我的',
    loginName:'ชื่อผู้ใช้', //'登录名',
    aboutUs:'เกี่ยวกับ', //'关于我们',
  // TODO NavBar
    home:'ที่บ้าน', //'首页',
    sort:'หมวดหมู่', //'分类',
  // TODO Common
    loading:'กำลังโหลด', //'加载中...',
    logIn:'ล็อกอิน', //'登录',
    signIn:'ลงทะเบียน', //'注册',
    noMore:'พอกันที', //'没有更多了',
    saveSuccess:'สำเร็จ', //'保存成功',
    deletSuccess:'ลบเรียบร้อยแล้ว', //'删除成功',
    placeholder:'โปรดค้นหา', //'请搜索您喜欢的商品',
    changeLang:'เปลี่ยนภาษา (บาฮาซาอินโดนีเซีย)', //'切换语言'
  // TODO Pay
    payName:'ชื่อการชำระเงิน', //'支付姓名',
    wallet:'กระเป๋าคุมข้อมูล', //'钱包',
    internetbank:'อีแบงค์', //'网银',
    cod:'จ่ายตอนส่งค่ะ',// 货到付款
    placeholder7:'กรุณากรอกชื่อการชำระเงิน', //'请填写支付姓名',
    scanQr:'สแกน Qr',//扫码
    qris:'Qris จ่ายเงิน',//Qris支付
    pay:'จ่ายเงิน',//支付
    bankcardCode:'รหัสบัตรธนาคาร',//银行卡编码
    confirmPayment:'ยืนยันการชำระเงิน',//确认支付
    pleaseSelectCardCode:'เลือกรหัสบัตร',//请选择银行卡编码
    confirmCancelPayment:'ยืนยันการยกเลิกการชำระเงินเลยมั้ยคะ',//确认取消支付吗？
  // TODO Share
    shareProfits:'กำไรร่วมกัน',//分享赚金
    invitationLink:'เชื่อมโยงการเชื้อเชิญ',//我的邀请链接
    invitationStep:'ขั้นตอนการเชื้อเชิญ',//邀请步骤
    chooseOne:'เลือก อันเดียวค่ะ',//以下任选其一
    tips1:'1. คัดลอกการเชื่อมโยงการเชื้อเชิญด้านบน และส่งไปยังเพื่อนของคุณ',//1.复制上面的邀请链接，发送给您的好友
    tips2:'2. เลือกหนึ่งในโปสเตอร์ด้านล่าง ที่คุณต้องการดาวน์โหลดไปยังโทรศัพท์ของคุณ และส่งไปยังเพื่อนของคุณ',//2.选择一款下面你喜欢的海报下载到手机，可以发送给您的好友
    tips:'พวกเขาจะกลายเป็นแฟนของคุณอย่างถาวร และจำนวนคำสั่งซื้อ ที่พวกเขาสร้างขึ้นในอนาคตจะได้รับรางวัล และเครดิตไปยังบัญชีของคุณโดยอัตโนมัติ',//Tips：好友通过您的邀请链接或者扫描您的海报二维码成功注册后，Ta将永久成为您的粉丝，未来Ta产生的订单金额，您都有奖励，会自动计入您的账户中
    posters:'สร้างโปสเตอร์',//一键生成专属海报
    saveScreenshot:'หน้าจอ ที่ถูกกด/ตัด บันทึก',//长按保存截图
    cancel:'ยกเลิก',//关闭
    youHaveGot:'คุณมี',//您已赚取
    bonus:'โบนัส',//奖金



    bonusDetail:'รายละเอียดโบนัส',// 奖金明细
    withdrawalApplication:'โปรแกรมการถอน',// 提现申请
    totalContribution:'การสนับสนุนทั้งหมด',// 贡献总金额
    amountWithdrawalNotbeLess1:'จำนวนเงิน ที่ใช้ในการถอนไม่น้อยกว่า 1',// 提现申请金额不能小于1
    withdrawalApplicationReview:'เริ่มการถอนโปรแกรมเรียบร้อยแล้วโปรดรอการตรวจสอบพื้นหลัง',// 提现申请已发起成功，请等待后台审核
    //TODO GoogleRegister
    bindAccount:'บัญชีผูกมัด',// 绑定账号
    bindingSuccessful:'การเชื่อมต่อสำเร็จ',// 绑定成功
    //TODO DoTask
    moneyDoingMissions:'ทำเงินให้กับภารกิจ',// 做任务赚金
    doTaskCollectGift:'ทำงานรับของขวัญ',// 做任务，薅礼金
    taskStep:'ขั้นตอนงาน',// 任务步骤
    icon:'ไอคอน',// 图标
    goToProductDetailsAny:'ไป ที่หน้ารายละเอียดผลิตภัณฑ์ของรายการใด ๆ',// 前往任何商品的详情页
    clickTopRight:'คลิก ที่มุมขวาบน',// 点击右上角
    copyProductDetailsLink:'คัดลอกรายละเอียดของผลิตภัณฑ์ ที่เชื่อมโยง',// 复制商品详情链接
    sendYourFriends:'ส่งไปยังเพื่อนของคุณด้วยการคลิก',// 发送给您的朋友点击
    view:'มุมมอง',// 查看
    canObtainCorresponding:'สามารถรับ ที่สอดคล้องกัน',// 即可获得相应
    rewards:'รางวัล',// 奖励
    taskReward:'รางวัลงาน',// 任务奖励
    be:'เป็น',// 被
    personView:'มุมมองบุคคล',// 人查看
    gain:'เพิ่ม',// 得到
    note:'หมายเหตุ: ยิ่งผู้คนดูรายละเอียดของผลิตภัณฑ์ ที่เชื่อมโยง',// 注：商品详情链接被查看的人数越多
    moreRewards:'ยิ่งได้รางวัลมากเท่าไหร่',// 获得奖励就会越多
    countdownMission:'นับถอยหลังสู่ภารกิจของคุณ',// 您的任务倒计时
    day:'วัน',// 天
    when:'เมื่อไหร่ล่ะ',// 时
    points:'จุด',// 分
    seconds:'วินาที',// 秒
    taskProgress:'ความคืบหน้างานของคุณ',// 您的任务进度
    currentlyCompletedNumber:'จำนวนงาน ที่เสร็จสมบูรณ์ในปัจจุบัน',// 当前任务完成数量
    notReapply:'ไม่ต้องสมัครใหม่',// 请勿重新申请
    applicationSuccessful:'โปรแกรมสำเร็จแล้ว',// 申请成功
    loginSuccessful:'การล็อกอินสำเร็จแล้ว',// 登录成功
    googleAuthorizationFailed:'การตรวจสอบสิทธิ์ของ Google ล้มเหลวโปรดรีเฟรช และลองใหม่อีกครั้ง',// 谷歌授权失败，请刷新重试
    accumulateRewards:'สะสมรางวัล',// 累计得到奖励
    bonusBalance:'ความสมดุล',// 奖金余额
    accumulatedBonus:'สะสม',// 累计奖金
    withdrawing:'ถอนตัว...',// 提现中
    sureWithdrawal:'คุณแน่ใจ ที่จะสมัครขอถอนยอดเงินโบนัสทั้งหมด หรือไม่',// 确定将全部奖金余额发起提现申请？

    name:'ชื่อ',//姓名
    enteryourname:'ป้อนชื่อของคุณ',//请输入姓名
    telephone:'โทรศัพท์',//电话
    enteryourtelephone:'ป้อนหมายเลขโทรศัพท์',//请输入姓名
    address:'ที่อยู่',//地址
    selectaddress:'เลือก ที่อยู่',//请选择地址
    detailedaddress:'ที่อยู่รายละเอียด',//详细地址
    enterfulladdress:'ป้อน ที่อยู่เต็ม',//请输入详细地址
    setdefaultaddress:'ตั้ง ที่อยู่ปริยาย',//设为默认地址
    noteaddress:'หมายเหตุ: ที่อยู่ปัจจุบัน',//注：当前地址
    support:'รองรับ',//支持
    nonsupport:'ไม่สนับสนุน',//不支持
    cashondelivery:'เงินสดเมื่อส่งมอบ ( COD )',//货到付款（COD）
    total:'รวม',//合计
    freight:'สินค้าบรรจุ',//运费
    currentshippingcosts:'ค่าจัดส่งปัจจุบันคือ :',//当前运费为：
    rememberCourier:'โปรดจำไว้ว่า จะส่งเงินให้คนส่งของ',//请记得连同货款交付给快递员哦~
    delete:'ลบ',//删除

    tip:'ทิป',//提示
    payCloseTip:'ระบบตรวจพบว่า คุณยังไม่ได้ชำระเงินสำเร็จ และการปิดหน้าต่างนี้ โดยไม่ต้องถ่ายภาพหน้าจออาจทำให้การชำระเงินล้มเหลวคุณอยากจะปิดมันไหม?',//系统检测到您当前还未支付成功，未截图且关闭此窗口可能导致支付失败，是否关闭?
    sure:'ได้เลยค่ะ',//确定
    paymentSuccess:'การชำระเงินสำเร็จ',//支付成功

    firstSingleMinus1:'รายการแรกลดลง !!!', //首单立减2000 ！！！
    firstSingleMinus2:' !!!', //首单立减2000 ！！！
    theFirstOrder1:'การสั่งซื้อครั้งแรก ที่ประสบความสำเร็จจะได้รับ ',//首次下单成功，即可获得2000
    theFirstOrder2:' รางวัลเมื่อสั่งซื้อสำเร็จ และสามารถรับได้ ที่ 【ฉันเอง】 เพียงแค่สั่งซื้อ.~', //奖励，下单成功后，可在个人中心领取，赶紧下单吧~
    congratulationOrder:'ยินดีด้วยนะคะ ที่สั่งเรียบร้อยแล้ว!!!', //恭喜您，下单成功！！！
    youWillReceive:'คุณจะได้รับ equity', //您将获得以下权益
    aSuccessful1:'ได้รับรางวัล ', //成功
    aSuccessful2:'สำเร็จ และสามารถตรวจสอบได้ในภายหลัง ที่ 【ฉันเอง】', //获得2000奖励，稍后可在个人中心查看
    youCanShare1:'คุณสามารถแบ่งปันลิงค์นี้ เพื่อวางคำสั่งซื้อกับเพื่อนของคุณเขาจะได้รับ', //可分享此链接给您朋友下单，他将获得
    youCanShare2:'รางวัล และคุณจะได้รับค่าคอมมิชชั่น ที่เกี่ยวข้องรายละเอียด 【ฉันเอง】【กำไรร่วมกัน】', //2000奖励，以及您也会获得相应佣金，详见个人中心【分享赚金】
    youCanShareView:'คุณสามารถแบ่งปันลิงค์นี้ กับเพื่อนของคุณเพื่อดูว่า มีผู้ ที่ต้องการมากขึ้น และคุณยังจะได้รับค่าตอบแทนการตรวจสอบ ที่เกี่ยวข้องรายละเอียด 【ฉันเอง】【ทำเงินให้กับภารกิจ】', //可分享此链接给您朋友查看，查看得人越多，你也会获得相应的查看奖励佣金，详见个人中心【做任务赚金】
    goToPersonal:'มุ่งหน้าไป ที่ 【ฉันเอง】', //前往个人中心
    copyLink:'คัดลอก ที่อยู่เชื่อมโยง', //复制链接
    systemAssigns:'ระบบกำหนดรหัสผ่านของบัญชี', //系统分配账号密码

    theServer:'กรุณาติดต่อผู้ดูแลระบบ!',// 服务端异常，请联系管理员！
    automaticLogin:'ล็อกอินโดยอัตโนมัติ',// 自动登录
    theSystem:'ระบบตรวจสอบว่า คุณไม่ได้เข้าสู่ระบบ และได้กำหนดบัญชีให้กับคุณโดยอัตโนมัติ หรือมีบัญชีผู้ใช้อยู่แล้ว ที่เข้าสู่ระบบ/ลงทะเบียนเอง',// 系统检测您当前未登录，已自动为您分配账号，或者已有账号自行登录/注册。
    noteAutonomous:'หมายเหตุ: การจัดสรรบัญชี และรหัสผ่านสามารถแก้ไขได้ ที่ศูนย์ส่วนบุคคล',// 注：分配账号以及密码可在个人中心修改
    autonomousLogin:'ล็อกอินอัตโนมัติ/ลงทะเบียน',// 自主登录/注册
    assignedAccount :'กำหนดการล็อกอินของบัญชีผู้ใช้',// 分配账号登录
    noteRemember:'หมายเหตุ: อย่าลืมบันทึกด้วยตนเองหลังจากทำสำเนา.โอ้ตามด้วยสามารถแก้ไขบัญชี และรหัสผ่าน ที่ศูนย์ส่วนบุคคล และรับรางวัล!',// 注：记得复制后自行保存哦，后续可在个人中心修改账号和密码以及领取奖励！
    successfulReplication:'คัดลอกเรียบร้อยแล้ว',// 复制成功
  },
}

export default th;






