
<template>
  <div class="create-order">
    <s-header :name="$t('t.generateOrder')" @callback="deleteLocal"></s-header>
    <div class="address-wrap">
      <div class="name" @click="goTo">
        <span>{{ address.userName }} </span>
        <span>{{ address.userPhone }}</span>
      </div>
      <div class="address">
        {{ address.provinceName }} {{ address.cityName }} {{ address.regionName }} {{ address.detailAddress }}
      </div>
      <van-icon class="arrow" name="arrow" />
    </div>
    <div class="good">
      <div class="good-item" v-for="(item, index) in cartList" :key="index">
        <div class="good-img"><img :src="JSON.parse(item.goodsCoverImg)[0]" alt=""></div>
        <div class="good-desc">
          <div class="good-title">
            <span>{{ item.goodsName }}</span>
            <span>x{{ item.goodsCount }}</span>
          </div>
          <div class="good-btn">
            <div class="price">{{ item.sellingPrice | wq_mFormat}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pay-wrap">
      <van-row class="padding15" type="flex" align="center" justify="space-between">
        <van-row>{{$t('t.total')}}</van-row>
        <van-row class="colorTheme size16 fontB">{{feeData.totalPrice | wq_mFormat}}</van-row>
      </van-row>
      <van-row class="padding15" type="flex" align="center" justify="space-between">
        <van-row>{{$t('t.commodityAmount')}}</van-row>
        <van-row>{{feeData.orderPrice | wq_mFormat}}</van-row>
      </van-row>
      <van-row class="padding15" type="flex" align="center" justify="space-between">
        <van-row>{{$t('t.freight')}}</van-row>
        <van-row>{{feeData.logisticsFees | wq_mFormat}}</van-row>
      </van-row>
      <van-button @click="createOrder" class="pay-btn" color="#e92d74" type="primary" block>{{$t('t.generateOrder')}}</van-button>
    </div>
    <pay :orderNo="orderNo" :codCover="address.newBeeMallAddress?address.newBeeMallAddress.codCover:'N'" :logisticsFees="feeData.logisticsFees" :payBool="payBool" @payBackFn="payBackFn"></pay>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { getCart, getByCartItemIds } from '../service/cart'
import { getDefaultAddress, getAddressDetail } from '../service/address'
import { createOrder,createOrderCalc, payOrder,queryPay,sendPay,sendCode,payFn,payAfter,getOrderDetail} from '../service/order'
import { setLocal, getLocal } from '@/common/js/utils'
import pay from '../components/pay'
export default {
  components: {
    sHeader,
    pay
  },
  data() {
    return {
      cartList: [],
      address: {},
      showPay: false,
      orderNo: '',
      cartItemIds: [],
      payBool:false,
      feeData:''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    payBackFn(data){
      this.payBool = false
      // if(data){
      //
      // }
    },

    async init() {
      this.$toast.loading({ message: this.$t('t.loading'), forbidClick: true });
      const { addressId, cartItemIds } = this.$route.query
      const _cartItemIds = cartItemIds ? JSON.parse(cartItemIds) : JSON.parse(getLocal('cartItemIds'))
      setLocal('cartItemIds', JSON.stringify(_cartItemIds))
      const { data: list } = await getByCartItemIds({ cartItemIds: _cartItemIds.join(',') })
      const { data: address } = addressId ? await getAddressDetail(addressId) : await getDefaultAddress()
      if (!address) {
        this.$router.push({ path: 'address' })
        return
      }
      this.cartList = list
      this.address = address
      this.createOrderCalc()
      this.$toast.clear()
    },
    goTo() {
      this.$router.push({ path: `address?cartItemIds=${JSON.stringify(this.cartItemIds)}` })
    },
    deleteLocal() {
      setLocal('cartItemIds', '')
    },
    async createOrderCalc() {
      const params = {
        addressId: this.address.addressId,
        cartItemIds: this.cartList.map(item => item.cartItemId)
      }
      const { data, resultCode } = await createOrderCalc(params)
      this.feeData = data
    },
    async createOrder() {
      const params = {
        addressId: this.address.addressId,
        cartItemIds: this.cartList.map(item => item.cartItemId)
      }
      const { data, resultCode } = await createOrder(params)
      setLocal('cartItemIds', '')
      this.orderNo = data
      this.payBool = true
    },
    close() {
      this.$router.push({ path: 'order' })
    },
  },
  computed: {
    total: function() {
      let sum = 0
      this.cartList.forEach(item => {
        sum += item.goodsCount * item.sellingPrice
      })
      return sum
    }
  },
  // 页面销毁前
  beforeDestroy(){
    clearTimeout(this.verfBool);
    this.verfBool = null;
    clearTimeout(this.proTime);
    this.proTime = null;
  },
}
</script>

<style lang="less" scoped>
  @import '../common/style/mixin';
  .create-order {
    background: #f9f9f9;
    .address-wrap {
      margin-bottom: 20px;
      background: #fff;
      position: relative;
      margin-top: 44px;
      font-size: 14px;
      padding: 15px;
      color: #222333;
      .name, .address {
        margin: 10px 0;
      }
      .arrow {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
      }
      &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 2px;
        background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
        background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
        background-size: 80px;
        content: '';
      }
    }
    .good {
      margin-bottom: 120px;
    }
    .good-item {
      padding: 10px;
      background: #fff;
      display: flex;
      .good-img {
        img {
          .wh(100px, 100px)
        }
      }
      .good-desc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 20px;
        .good-title {
          display: flex;
          justify-content: space-between;
        }
        .good-btn {
          display: flex;
          justify-content: space-between;
          .price {
            font-size: 16px;
            color: red;
            line-height: 28px;
          }
          .van-icon-delete {
            font-size: 20px;
            margin-top: 4px;
          }
        }
      }
    }
    .pay-wrap {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      padding: 10px 0;
      padding-bottom: 50px;
      border-top: 1px solid #e9e9e9;
      >div {
        display: flex;
        justify-content: space-between;
        padding: 0 5%;
        margin: 10px 0;
        font-size: 14px;
        span:nth-child(2) {
          color: red;
          font-size: 18px;
        }
      }
      .pay-btn {
        position: fixed;
        bottom: 7px;
        right: 0;
        left: 0;
        width: 90%;
        margin: 0 auto;
      }
    }
  }
  .width150{width: 150px}
  .width100{width: 80px}
  .paddBott3{padding-bottom: 3px}
  .borderTopE4{border-top: 1px solid #e4e4e4}
</style>
