
<template>
  <div class="order-detail-box">
    <s-header :name="$t('t.orderDetails')" :back="'/order'" @callback="close"></s-header>
    <div class="order-status">
      <div class="status-item">
        <label>{{$t('t.orderStatus')}}：</label>
        <span>{{ detail.orderStatusString }}</span>
      </div>
      <div class="status-item">
        <label>{{$t('t.orderNumber')}}：</label>
        <span>{{ detail.orderNo }}</span>
      </div>
      <div class="status-item">
        <label>{{$t('t.orderTime')}}：</label>
        <span>{{ detail.createTime }}</span>
      </div>
      <div class="status-item" v-if="detail.orderStatus == 3 && detail.logisticsNo">
        <label>{{$t('t.logisticsInfo')}}：</label>
        <span @click="doCopy(detail.logisticsNo)"> {{detail.logisticsNo}}
          <span class="marLeft10 colorBlue">【{{$t('t.copy')}}】</span>
        </span>
      </div>
      <van-button v-if="detail.orderStatus == 3" style="margin-bottom: 10px" color="#e92d74" block @click="handleConfirmOrder(detail.orderNo)">{{$t('t.confirmReceipt')}}</van-button>
      <van-button v-if="detail.orderStatus == 0" style="margin-bottom: 10px" color="#e92d74" block @click="orderNo = detail.orderNo,payBool = true">{{$t('t.goPay')}}</van-button>
      <van-button v-if="detail.orderStatus == 0" block @click="cancelOrder(detail.orderNo)">{{$t('t.cancelOrder')}}</van-button>
    </div>
    <div class="order-price">
      <div class="price-item">
        <label>{{$t('t.commodityAmount')}}：</label>
        <span>{{ detail.orderPrice | wq_mFormat }}</span>
      </div>
      <div class="price-item">
        <label>{{$t('t.freight')}}：</label>
        <span> {{ detail.logisticsFees | wq_mFormat }}</span>
      </div>
      <div class="price-item">
        <label>{{$t('t.total')}}：</label>
        <span class="colorTheme size16 fontB">{{ detail.totalPrice | wq_mFormat }}</span>
      </div>
      <!--<div class="price-item">-->
        <!--<label>{{$t('t.distributionMode')}}：</label>-->
        <!--<span>{{$t('t.ordinaryExpress')}}</span>-->
      <!--</div>-->
    </div>
    <van-card
      v-for="item in detail.newBeeMallOrderItemVOS"
      :key="item.goodsId"
      style="background: #fff;margin-bottom: 10px"
      :num="item.goodsCount"
      :price="item.sellingPrice | wq_mFormat"
      desc=""
      :title="item.goodsName"
      :thumb="JSON.parse(item.goodsCoverImg)[0]">
      <template #price>
        {{item.sellingPrice | wq_mFormat}}
      </template>
      <template #footer>
        <van-button v-if="detail.orderStatus == 4 && item.evaluateStatus == 0" size="small" type="primary" @click="evaluateFn(item.goodsId,item.tag)">{{$t('t.immediateEvaluation')}}</van-button>
      </template>
    </van-card>

    <van-dialog
      v-model="evaluateBool"
      :title="$t('t.evaluate')"
      show-cancel-button @confirm="saveEvaluateFn"
      :confirm-button-text="$t('t.save')">
      <van-field label-width="1.6rem" name="level" :label="$t('t.comprehensiveEvaluation')">
        <template #input>
          <van-rate color="#ffd21e" void-icon="star" void-color="#eee" v-model="form.level" />
        </template>
      </van-field>
      <van-field
        label-width="1.6rem"
        v-model="form.content"
        rows="2"
        autosize
        :label="$t('t.evaluationContent')"
        type="textarea"
        maxlength="200"
        :placeholder="$t('t.evaluationContentPlaceholder')"
        show-word-limit
      />
      <van-field label-width="1.6rem" name="images" :label="$t('t.photoVideo')">
        <template #input>
          <van-row type="flex" align="center">
            <van-row  v-for="(img,i) in form.images" :key="i" class="imgBox">
              <img v-if="isImg(img)" :src="img" @click="imgBigUrl= img,imgBool = true" class="avatar" style="width:100%;height: 100%;"/>
              <video v-else controls="controls" style="width:100%;height: 100%;" width="100%" height="100%" preload="metadata">
                <source :src="img" type="video/ogg">
                <source :src="img" type="video/webm">
                <source :src="img" type="video/mp4">
              </video>
              <van-icon name="close" class="positionRT0"  @click="form.images.splice(i, 1)"/>
            </van-row>
            <van-row type='flex' align='center' justify='center' class="imgBox pointer relative">
              <input class="inputUploadBox" type="file" accept="video/*,image/*"
                     @change="afterRead">
              <van-icon name="plus" class="size36 color99"/>
            </van-row>
          </van-row>


          <!--<van-uploader accept="video/*,image/*" v-model="form.images" :max-count="5" :after-read="afterRead">-->
            <!--<template #preview-cover="{ file }">-->

            <!--</template>-->
          <!--</van-uploader>-->
        </template>
      </van-field>
    </van-dialog>
    <van-image-preview v-model="imgBool" :images="[imgBigUrl]"></van-image-preview>
    <pay v-if="detail.newBeeMallOrderAddress" :logisticsFees="detail.logisticsFees" :codCover="detail.newBeeMallOrderAddress.newBeeMallAddress.codCover" :orderNo="orderNo" :payBool="payBool" @payBackFn="payBool = false,init()"></pay>
  </div>
</template>

<script>
  import { doCopy } from '@/common/js/utils'
  import sHeader from '@/components/SimpleHeader'
  import { uploadImg } from '../service/user'
  import pay from '../components/pay'
import { getOrderDetail, cancelOrder, confirmOrder, saveEvaluate} from '../service/order'
export default {
  components: {
    sHeader,
    pay
  },
  data() {
    return {
      detail: {},
      showPay: false,
      evaluateBool:false,
      form:{
        orderId:'',
        goodsId:'',
        level:'5', //级别 1-5
        images:[], //评价图片["",""]
        content:'', //评论
        goodsDescription:'', //商品描述
      },
      imgBool:false,
      imgBigUrl:'',
      orderNo:'',
      payBool:false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    doCopy(val){
      doCopy(val)
    },
    isImg(url){
      let arr = url.split(".")
      let str = arr[arr.length-1]
      return ['jpg','jpeg','png'].includes(str)
    },
    async init() {
      this.$toast.loading({
        message: this.$t('t.loading'),
        forbidClick: true
      });
      const { id } = this.$route.query
      const { data } = await getOrderDetail(id)
      this.detail = data
      this.form.orderId = data.orderId
      this.$toast.clear()
    },
    cancelOrder(id) {
      this.$dialog.confirm({
        title: this.$t('t.confirmCancellation'),
      }).then(() => {
        cancelOrder(id).then(res => {
          if (res.resultCode == 200) {
            this.$toast(this.$t('t.cancelSuccess'))
            this.init()
          }
        })
      }).catch(() => {
        // on cancel
      });
    },
    handleConfirmOrder(id) {
      this.$dialog.confirm({
        title: this.$t('t.isConfirmReceipt'),
      }).then(() => {
        confirmOrder(id).then(res => {
          if (res.resultCode == 200) {
            this.$toast(this.$t('t.confirmSuccess'))
            this.init()
          }
        })
      }).catch(() => {
        // on cancel
      });
    },
    close() {
      this.$dialog.close()
    },
    evaluateFn(goodsId,tag){
      this.form.goodsId = goodsId
      this.form.goodsDescription = tag
      this.evaluateBool = true
    },
    async saveEvaluateFn() {
      let parms = JSON.parse(JSON.stringify(this.form))
      parms.images = JSON.stringify(parms.images)
      await saveEvaluate(parms)
      this.evaluateBool = false
      this.init()
      this.$toast.success(this.$t('t.saveSuccess'))
    },
    afterRead(e) {
      this.$toast.loading({
        message: this.$t('t.loading'),
        forbidClick: true
      });
      let file;
      let $target
      if(e.target || e.srcElement){
        $target = e.target || e.srcElement;
        file = $target.files[0] || e;
      }else{
        file = e
      }
      if (!file) {
        return
      }
      let formData = new FormData();
      formData.append("file", file);
      uploadImg(formData).then(res => {
        this.form.images.push(res.data)
        this.$toast.clear()
        if($target) $target.value = '';
      })
      // setTimeout(() => {
      //   file.status = 'failed';
      //   file.message = '上传失败';
      // }, 1000);
    },
  },
  // 页面销毁前
  beforeDestroy(){
    clearTimeout(this.verfBool);
    this.verfBool = null;
    clearTimeout(this.proTime);
    this.proTime = null;
  },
}
</script>

<style lang="less" scoped>
  .order-detail-box {
    background: #f7f7f7;
    .order-status {
      background: #fff;
      margin-top: 44px;
      padding: 20px;
      font-size: 15px;
      .status-item {
        margin-bottom: 10px;
        label {
          color: #999;
        }
        span {

        }
      }
    }
    .order-price {
      background: #fff;
      margin: 20px 0;
      padding: 20px;
      font-size: 15px;
      .price-item {
        margin-bottom: 10px;
        label {
          color: #999;
        }
        span {

        }
      }
    }
    .van-card {
      margin-top: 0;
    }
    .van-card__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .flexDirection{flex-direction: column;}
  .padding30{padding: 30px}


.size36{
  font-size: 20px;
}
  .width150{width: 50px}
  .imgBox{
    width: 50px;
    height: 50px;
    border: 1px solid #f1f1f1;
    position: relative;
    margin-right: 10px;
  }
  .positionRT0{
    position: absolute;
    right: -8px;
    top: -8px;
    font-size: 16px;
    z-index: 10;
    color:#ff0000;
  }
  .imgBox /deep/ .van-icon-circle-close{
    color: #FFFFFF;
  }
  .inputUploadBox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .width150{width: 150px}
  .width100{width: 80px}
  .paddBott3{padding-bottom: 3px}
  .borderTopE4{border-top: 1px solid #e4e4e4}
</style>
