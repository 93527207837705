// 复制
import { Toast } from 'vant'
import {i18n} from '../../lang'
export function doCopy(value) {
  if(value){
    const oInput = document.createElement('textarea')
    oInput.value = value
    document.body.appendChild(oInput)
    oInput.select()
    document.execCommand('Copy')

    // removeChild ios无法复制
    // oInput.parentNode.removeChild(oInput);
    oInput.style.display = 'none'
    //console.log('复制成功')
    Toast.success(i18n.t('t.successfulReplication'))
  }else{
    Toast.fail('复制失败')
  }

}
export function getQueryString(name) {
  let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
  let search = window.location.hash.split("?")[1];
  if(!search) return null
  // let search = window.location.search
  let r = search.match(reg);
  if(r != null) {
    return  unescape(r[2]);
  } else {
    return null
  }
}

export const getLocal = (name) => {
  return localStorage.getItem(name)
}

export const setLocal = (name, value) => {
  localStorage.setItem(name, value)
}

// 图片前缀方法
export const prefix = (url) => {
  if (url && url.startsWith('http')) {
    return url
  } else {
    // url = `http://10.233.10.180:28019${url}`
    url = `https://api.szdd.xyz${url}`
    // url = `http://backend-api-01.newbee.ltd${url}`
    return url
  }
}
